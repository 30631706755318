import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import TableData from '../../../../components/TableData/TableData';
import { ContactPerson } from '../../../../models/types';
import { AuthContext } from '../../../../store/auth-context';

type Props = {
  values: ContactPerson;
  setOpenModal: any;
  setToBeDeleted: any;
};

function ProfileRow2({ values, setOpenModal, setToBeDeleted }: Props) {
  const navigate = useNavigate();
  const ctx = useContext(AuthContext);

  const onClickEditHandler = () => {
    ctx.setEditId(values.uid);
    navigate('/editcontactperson');
  };

  const onClickDeleteHandler = () => {
    setOpenModal(true);
    setToBeDeleted(values.uid);
  };

  return (
    <>
      <tr>
        <TableData>{values.name}</TableData>
        <TableData>{values.surname}</TableData>
        <TableData>
          <>
            <button type="button" onClick={onClickEditHandler}>
              Bearbeiten
            </button>
            <button type="button" onClick={onClickDeleteHandler}>
              Löschen
            </button>
          </>
        </TableData>
      </tr>
    </>
  );
}

export default ProfileRow2;
