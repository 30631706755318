import React, { useState } from 'react';

import usePut from '../../hook/usePut';
import { ProjectForm } from '../../models/types';
import EditProjectForm from '../../pages/ProfileDetails/TabContent/EditProjectForm';
import DeleteConfirm from '../DeleteConfirm/DeleteConfirm';

import styles from './Project.module.css';

type Props = {
  project: ProjectForm;
  deleteProject: (projectId: number) => void;
  updateProject: (project: ProjectForm) => void;
};
const Project = ({ project, deleteProject, updateProject }: Props) => {
  const [expandProject, setExpandProject] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { sendPutRequest } = usePut();

  const deleteProjectClick = () => {
    setDeleteConfirm(true);
  };

  const deleteProjectHandler = () => {
    if (project.uid) {
      deleteProject(project.uid);
      setDeleteConfirm(false);
    }
  };

  const abortDeleteHandler = () => {
    setDeleteConfirm(false);
  };

  const monthDiff = (dateFrom: Date, dateTo: Date) => {
    return (
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  };

  const timespan = () => {
    const diff = monthDiff(
      new Date(Date.parse(project.start)),
      !project.end ? new Date() : new Date(Date.parse(project.end))
    );
    if (diff < 12 && diff >= 0) {
      return `${diff} Monat${diff === 1 ? '' : 'e'}`;
    }
    if (diff === 12) {
      return '1 Jahr';
    }
    if (diff > 12) {
      const years = Math.floor(diff / 12);
      const months = diff - years * 12;
      return `${years} Jahr${years === 1 ? '' : 'e'} ${months} Monat${
        months === 1 ? '' : 'e'
      }`;
    }
    return 'k.A.';
  };

  const toggleExpandProject = () => {
    if (expandProject && editMode) {
      toggleEditMode();
    }
    setExpandProject((prevState) => !prevState);
  };

  const toggleEditMode = () => {
    setEditMode((prevState) => !prevState);
  };

  const endDate = () => {
    if (!project.end && new Date(Date.parse(project.start)) > new Date()) {
      return 'k.A.';
    } else if (!project.end) {
      project.end = '';
      return 'bis heute';
    }
    return project.end.slice(0, -3);
  };

  const updateProjectHandler = async (data: ProjectForm) => {
    const { httpErrorReturn } = await sendPutRequest(
      `/api/v1/consultant/project/${project.uid}`,
      data
    );
    if (!httpErrorReturn) {
      toggleEditMode();
      updateProject(data);
    }
  };

  return (
    <div className={styles['project-container']} key={project.name}>
      {deleteConfirm && (
        <DeleteConfirm
          confirmClick={deleteProjectHandler}
          abortClick={abortDeleteHandler}
          message={'Projekt wirklich löschen?'}
        />
      )}
      <div
        className={styles['click-area']}
        onClick={toggleExpandProject}
        aria-hidden="true"
        data-test-id="expand-project-area"
      ></div>
      <div className={styles['project-header']}>
        <div className={styles['timespan']}>{timespan()}</div>
        <div className={styles['dates']}>
          <div>{project.start.slice(0, -3)}</div>
          <div>-</div>
          <div>{endDate()}</div>
        </div>
        <div className={styles['project-title']}>
          <span className={styles['project-edit']}>
            <h2>{project.name}</h2>
            {expandProject && (
              <span>
                <button
                  type="button"
                  onClick={toggleEditMode}
                  data-testid="edit-button"
                >
                  Bearbeiten
                </button>
                <button type="button" onClick={deleteProjectClick}>
                  Löschen
                </button>
              </span>
            )}
          </span>
          <button
            type="button"
            className={styles['expand-button']}
            onClick={toggleExpandProject}
          >
            {'\u142f'}
          </button>
        </div>
      </div>

      {expandProject &&
        (editMode ? (
          <div className={styles['project-body']}>
            <div></div>
            <EditProjectForm
              values={project}
              abortHandler={toggleEditMode}
              restCall={updateProjectHandler}
            ></EditProjectForm>
          </div>
        ) : (
          <div className={styles['project-body']}>
            <div></div>
            <div className={styles['grid-container']}>
              <p className={styles['column-titles']}>Rolle </p>
              <p>{project.role}</p>
              <p className={styles['column-titles']}>Technologien </p>
              <div>
                {project.skills.map((t) => (
                  <div
                    className={styles['tech-button']}
                    key={`${project.name}${t}`}
                  >
                    <span>{t}</span>
                  </div>
                ))}
              </div>
              <p className={styles['column-titles']}>Beschreibung </p>
              <p>{project.descriptionLong}</p>
              <p className={styles['column-titles']}>Kunde </p>
              <p>{project.company}</p>
              <p className={styles['column-titles']}>Branche </p>
              <div>
                {project.industries.map((i) => (
                  <div
                    className={styles['tech-button']}
                    key={`${project.name}${i}`}
                  >
                    <span>{i}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
export default Project;
