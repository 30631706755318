import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import PageHeader from '../../components/PageHeader/PageHeader';
import { FieldsContext } from '../../store/fields-context';

import ProfileTable from './ProfileTable/ProfileTable';

function Startsite() {
  const [searchInput, setSearchInput] = useState('');
  const navigate = useNavigate();
  const { fieldsError } = useContext(FieldsContext);

  const searchInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value.toLowerCase());
  };

  const onClickHandler = () => {
    navigate('/new/profile');
  };

  return (
    <>
      <PageHeader
        title="Consultant Profile"
        buttonTitle="Neues Profil"
        onClick={onClickHandler}
        searchInput={searchInputHandler}
      />
      {!fieldsError ? (
        <ProfileTable filterString={searchInput}></ProfileTable>
      ) : (
        <p>{fieldsError}</p>
      )}
    </>
  );
}

export default Startsite;
