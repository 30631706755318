import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import usePut from '../../hook/usePut';
import { NewContactPersonForm } from '../../models/types';
import { FieldsContext } from '../../store/fields-context';
import { newContactPersonValidationSchema } from '../../util/schemes';
import { isApiErrorMessage } from '../../util/typeguards';
import Card from '../Card/Card';

import styles from './NewContactperson.module.css';

function NewContactPerson() {
  const navigate = useNavigate();
  const { titles, sexes, contactRoles } = useContext(FieldsContext);
  const { sendPutRequest } = usePut();
  const [personData] = useState<NewContactPersonForm>({
    name: '',
    surname: '',
    sex: '',
    email: '',
    phone: '',
    mobil: '',
    title: '',
    role: '',
  });

  const onSubmitHandler = async (data: NewContactPersonForm) => {
    const { httpErrorReturn } = await sendPutRequest(
      '/api/v1/fields/contactPerson/new',
      {
        ...data,
        name: data.name.trim(),
        surname: data.surname.trim(),
      }
    );

    if (httpErrorReturn === '') {
      navigate('/administration');
    } else if (isApiErrorMessage(httpErrorReturn)) {
      // api has failed for whatever reason
      // quick fix, as we dont have any sort of global error handling
      // and the backend isn't telling which field failed
      formik.setFieldError('email', 'E-Mail wird bereits genutzt.');
    } else {
      formik.setStatus('Konnte keine neue Kontaktperson hinzufügen.');
    }
  };

  const formik = useFormik({
    initialValues: personData,
    validationSchema: newContactPersonValidationSchema,
    onSubmit: () => {
      onSubmitHandler(formik.values);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Card title={'Geben Sie Ihre Persönlichen Daten ein.'}>
          <div className={styles['grid-container']}>
            <p className={styles['colum-title']}>Geschlecht*</p>
            <div>
              <select
                className={styles['title-input']}
                value={formik.values.sex}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="sex"
                data-testid="select-gender"
              >
                <option hidden>Auswählen ...</option>
                {sexes.map((s) => (
                  <option key={s.name}>{s.name}</option>
                ))}
              </select>
              <span className={styles['error-message']}>
                {formik.touched.sex && formik.errors.sex
                  ? formik.errors.sex
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>Rolle*</p>
            <div>
              <select
                className={styles['title-input']}
                value={formik.values.role}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="role"
                data-testid="select-role"
              >
                <option hidden>Auswählen ...</option>
                {contactRoles.map((s) => (
                  <option key={s.name}>{s.name}</option>
                ))}
              </select>
              <span className={styles['error-message']}>
                {formik.touched.role && formik.errors.role
                  ? formik.errors.role
                  : null}
              </span>
            </div>

            <p className={styles['colum-title']}>Titel</p>
            <select
              className={styles['title-input']}
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="title"
              data-testid="select-title"
            >
              <option hidden>Auswählen ...</option>
              {titles.map((t) => (
                <option key={t.name}>{t.name}</option>
              ))}
            </select>

            <p className={styles['colum-title']}>Vorname*</p>
            <div className={styles['smaller']}>
              <input
                className={
                  formik.touched.name && formik.errors.name
                    ? styles['is-invalid']
                    : undefined
                }
                type="text"
                placeholder="Ihr Vorname hier eingeben ..."
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="name"
              ></input>

              <span className={styles['error-message']}>
                {formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>Nachname*</p>
            <div className={styles['smaller']}>
              <input
                className={
                  formik.touched.surname && formik.errors.surname
                    ? styles['is-invalid']
                    : undefined
                }
                type="text"
                placeholder="Ihr Nachname hier eingeben ..."
                value={formik.values.surname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="surname"
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.surname && formik.errors.surname
                  ? formik.errors.surname
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>E-Mail*</p>
            <div className={styles['smaller']}>
              <input
                type="text"
                placeholder="Ihre E-Mail hier eingeben ..."
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="email"
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>Telefonnr.*</p>
            <div className={styles['smaller']}>
              <input
                type="text"
                placeholder="Ihre Telefonnummer hier eingeben ..."
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="phone"
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.phone && formik.errors.phone
                  ? formik.errors.phone
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>Handynr.</p>
            <div className={styles['smaller']}>
              <input
                type="text"
                placeholder="Ihre Handynummer hier eingeben ..."
                value={formik.values.mobil}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="mobil"
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.mobil && formik.errors.mobil
                  ? formik.errors.mobil
                  : null}
              </span>
            </div>
          </div>
        </Card>
        <div className={`${styles['navigation']} ${styles['red']} `}>
          {formik.status}
        </div>
        <div className={`${styles['navigation']}`}>
          <button type="submit"> Hinzufügen </button>
        </div>
      </form>
    </>
  );
}

export default NewContactPerson;
