import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';

type AuthContextObj = {
  bearerToken: string;
  restURL: string;
  userRoles: string[];
  editId: number;
  setEditId: (id: number) => void;
  login: (key: string) => void;
  logout: () => void;
  loggedIn: boolean;
};

type JWT = {
  exp: number;
  iat: number;
  resources: { CONSULTANT: number };
  iss: string;
  roles: string[];
  sub: string;
};

type Props = {
  children: React.ReactNode;
};

export const AuthContext = React.createContext<AuthContextObj>({
  bearerToken: '',
  restURL: '',
  userRoles: [''],
  editId: 0,
  setEditId: (id: number) => {}, // eslint-disable-line
  login: (key: string) => {}, // eslint-disable-line
  logout: () => {},
  loggedIn: false,
});

const AuthContextProvider = ({ children }: Props) => {
  const restURL = process.env.REACT_APP_API_URL;
  const [loginStatus, setLoginStatus] = useState(false);
  const [editId, setEditId] = useState('0');
  const [userRoles, setUserRoles] = useState(['']);

  useEffect(() => {
    const token = localStorage.getItem('restToken');
    if (token) {
      setLoginStatus(true);
      setEditId(localStorage.getItem('editId')!);

      const decode: JWT = jwt_decode(token);
      setUserRoles(decode.roles);
      expirationLogoutHandler(decode.exp);
    } // eslint-disable-next-line
  }, []);

  const loginHandler = (secret: string) => {
    const decode: JWT = jwt_decode(secret);
    determineId(decode);
    expirationLogoutHandler(decode.exp);

    localStorage.setItem('restToken', secret);
    setUserRoles(decode.roles);
    setLoginStatus(true);
  };

  const determineId = (decode: JWT) => {
    if (decode.roles.includes('CONSULTANT')) {
      const id = JSON.stringify(decode.resources.CONSULTANT);
      localStorage.setItem('editId', id);
      setEditId(id);
    } else {
      localStorage.setItem('editId', '0');
    }
  };

  const addEditIdHandler = (id: number) => {
    setEditId(id.toString());
    localStorage.setItem('editId', JSON.stringify(id));
  };

  const logoutHandler = () => {
    localStorage.clear();
    setLoginStatus(false);
  };

  const calculateRemainingTime = (expirationTime: number) => {
    return expirationTime - new Date().getTime();
  };

  const expirationLogoutHandler = (exp: number) => {
    const expirationTime = exp * 1000;

    const remainingTime = calculateRemainingTime(expirationTime);
    setTimeout(logoutHandler, remainingTime);
  };

  const contextValues: AuthContextObj = {
    bearerToken: localStorage.getItem('restToken')!,
    restURL: restURL!,
    userRoles: userRoles,
    editId: Number(editId),
    setEditId: addEditIdHandler,
    login: loginHandler,
    logout: logoutHandler,
    loggedIn: loginStatus,
  };

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
