import { useFormik } from 'formik';
import React, { useState, useRef, useEffect } from 'react';
import * as Yup from 'yup';

import AreaSelection from '../../../components/AreaSelection/AreaSelection';
import Card from '../../../components/Card/Card';
import CityHelper from '../../../components/CityHelper/CityHelper';
import FormNavigation from '../../../components/FormNavigation/FormNavigation';
import { AvailabilityForm, City } from '../../../models/types';

import styles from './AvailabilityInput.module.css';

type Props = {
  initValues: AvailabilityForm;
  submitData: (data: {
    newEntries: { city: string; radius: number }[];
    deletedEntries: number[];
    postalAreas: boolean[];
    availableFrom: string;
    hoursPerWeek: number;
  }) => void;
  onAbort: (data: AvailabilityForm) => void;
  customRightButton: string;
  extraStyling: boolean;
  restStatusMessage: string;
};

function AvailabilityInput({
  initValues,
  submitData,
  onAbort,
  customRightButton,
  extraStyling,
  restStatusMessage,
}: Props) {
  const [rangeValue, setRangeValue] = useState(0);
  const [rangeSliderValue, setrangeSliderValue] = useState(0);
  const [selectedCities, setSelectedCities] = useState<City[]>([]);
  const [newCities, setNewCities] = useState<
    { city: string; radius: number }[]
  >([]);
  const [deletedCities, setDeletedCities] = useState<number[]>([]);
  const [citiesContent, setCitiesContent] = useState(<br></br>);
  const radiusRef = useRef<HTMLSelectElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const [selectedArea, setSelectedArea] = useState<boolean[]>([]);

  useEffect(() => {
    setCitiesContent(
      <div>
        {selectedCities!.map((entry) => (
          <CityHelper
            editRadiusHandler={editRadiusHandler}
            editNameHandler={editNameHandler}
            onDeleteCity={removeCityHandler}
            values={entry}
            key={entry.city}
          ></CityHelper>
        ))}
      </div>
    );
    // eslint-disable-next-line
  }, [selectedCities]);

  useEffect(() => {
    setSelectedArea(formik.values.postalAreas);
    setSelectedCities(formik.values.cities);
    setRangeValue(formik.values.hoursPerWeek);
    setrangeSliderValue(formik.values.hoursPerWeek); // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: initValues,
    validationSchema: Yup.object({
      availableFrom: Yup.string().required('Eingabe nötig'),
      hoursPerWeek: Yup.number().integer(),
    }),
    onSubmit: () => {
      const availabilitySubmitObject = {
        newEntries: newCities,
        deletedEntries: deletedCities,
        postalAreas: selectedArea,
        availableFrom: formik.values.availableFrom,
        hoursPerWeek: rangeValue,
      };
      submitData(availabilitySubmitObject);
    },
  });

  const onBackHandler = () => {
    formik.values.hoursPerWeek = rangeValue;
    formik.values.postalAreas = selectedArea!;
    formik.values.cities = selectedCities!;
    onAbort(formik.values);
  };
  const rangeSliderHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRangeValue(event.target.valueAsNumber);
    setrangeSliderValue(event.target.valueAsNumber);
  };

  const rangeInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRangeValue(event.target.valueAsNumber);
    setrangeSliderValue(event.target.valueAsNumber);
  };

  // Note: checking for an empty input field is ignored because of planned switch to dropdown
  const addCityHandler = () => {
    if (selectedCities.find((e) => e.city === cityRef.current!.value)) {
      return;
    }

    if (radiusRef.current!.value === 'Auswählen...') {
      radiusRef.current!.value = '10';
    }

    let itemId = 0;
    if (!selectedCities.length) {
      itemId = 0;
    } else {
      const previousIds = selectedCities.map((item) => {
        return item.uid;
      });
      itemId = Math.max(...previousIds) + 1;
    }
    setSelectedCities((prevState) => [
      ...prevState,
      {
        uid: itemId,
        city: cityRef.current!.value,
        radius: Number(radiusRef.current!.value),
      },
    ]);

    setNewCities((prevState) => [
      ...prevState,
      {
        city: cityRef.current!.value,
        radius: Number(radiusRef.current!.value),
      },
    ]);
  };

  const removeCityHandler = (uid: number) => {
    setSelectedCities(selectedCities.filter((entry) => entry.uid !== uid));
    setDeletedCities((prevState) => [...prevState, uid]);
  };

  const editRadiusHandler = (city: string, newRadius: number) => {
    const existingCityIndex = selectedCities.findIndex(
      (item) => item.city === city
    );
    if (existingCityIndex >= 0) {
      setNewCities((prevState) => {
        return [...prevState, { city: city, radius: newRadius }];
      });
      setDeletedCities((prevState) => [
        ...prevState,
        selectedCities[existingCityIndex].uid,
      ]);
    }
  };

  const editNameHandler = (city: string, radius: number, newName: string) => {
    const existingCityIndex = selectedCities.findIndex(
      (item) => item.city === city
    );
    if (existingCityIndex >= 0) {
      setNewCities((prevState) => {
        return [...prevState, { city: newName, radius: radius }];
      });
      setDeletedCities((prevState) => [
        ...prevState,
        selectedCities[existingCityIndex].uid,
      ]);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Card title={'Tragen Sie Ihre Verfügbarkeit ein.'}>
          <div className={styles['grid-container']}>
            <p className={styles['colum-title']}>Verfügbar ab*</p>
            <div className={styles['input-error']}>
              <input
                className={
                  formik.touched.availableFrom && formik.errors.availableFrom
                    ? styles['is-invalid']
                    : undefined
                }
                data-testid="date-input"
                type="date"
                name="availableFrom"
                value={formik.values.availableFrom}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.availableFrom && formik.errors.availableFrom
                  ? formik.errors.availableFrom
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>
              Verfügbare Stunden/ Woche:*{' '}
            </p>
            <div className={styles['input-error']}>
              <input
                className={
                  formik.touched.hoursPerWeek && formik.errors.hoursPerWeek
                    ? styles['is-invalid']
                    : undefined
                }
                type="number"
                name="hoursPerWeek "
                min="0"
                max="48"
                step="1"
                onChange={rangeInputHandler}
                onBlur={formik.handleBlur}
                value={rangeValue ? rangeValue : '40'}
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.hoursPerWeek && formik.errors.hoursPerWeek
                  ? formik.errors.hoursPerWeek
                  : null}
              </span>
            </div>
            <br></br>
            <input
              onChange={rangeSliderHandler}
              type="range"
              min="0"
              max="48"
              step="1"
              value={rangeSliderValue ? rangeSliderValue : '40'}
            ></input>
            <p className={styles['colum-title']}>Einsatzorte</p>
            <hr />
            <p className={styles['colum-title']}>Städte*</p>
            <div className={styles.city}>
              <input ref={cityRef} type="text"></input>
              <select ref={radiusRef} defaultValue="Auswählen ...">
                <option hidden>Auswählen ...</option>
                <option value="10">10 km</option>
                <option value="20">20 km</option>
                <option value="50">50 km</option>
                <option value="100">100 km</option>
                <option value="150">150 km</option>
                <option value="200">200 km</option>
                <option value="300">300 km</option>
                <option value="500">500 km</option>
              </select>
              <button onClick={addCityHandler} type="button">
                Hinzufügen
              </button>
            </div>
            <br></br>
            <div>{citiesContent}</div>
            <p className={styles['colum-title']}>PLZ-Gebiete</p>
            <AreaSelection
              areaChange={setSelectedArea}
              activeArea={formik.values.postalAreas}
              readMode={false}
            ></AreaSelection>
          </div>
        </Card>

        <FormNavigation
          leftButton="Zurück"
          rightButton={customRightButton}
          onClickHandler={onBackHandler}
          restStatusMessage={restStatusMessage}
          extraStyling={extraStyling}
        ></FormNavigation>
      </form>
    </>
  );
}

export default AvailabilityInput;
