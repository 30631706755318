import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import EditContactpersonData from './components/EditContactpersonData/EditContactpersonData';
import HeaderNavbar from './components/HeaderNavbar/HeaderNavbar';
import LoginForm from './components/LoginForm/LoginForm';
import NewContactPerson from './components/NewContactperson/NewContactperson';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import WebsiteHeader from './components/WebsiteHeader/WebsiteHeader';
import Administration from './pages/Administration/Administration';
import CompetenceManager from './pages/CompetenceManager/CompetenceManager';
import ContactsManager from './pages/ContactsManager/ContactsManager';
import NewProfile from './pages/NewProfile/NewProfile';
import ProfileDetails from './pages/ProfileDetails/ProfileDetails';
import ProfileSearch from './pages/ProfileSearch/ProfileSearch';
import Startsite from './pages/Startsite/Startsite';
import { AuthContext } from './store/auth-context';

function App() {
  const ctx = useContext(AuthContext);
  const { userRoles, editId } = ctx;

  const pageContent = ctx.loggedIn ? (
    <>
      <HeaderNavbar userRoles={ctx.userRoles}></HeaderNavbar>
      <Routes>
        <Route
          path="/consultants"
          element={
            <ProtectedRoute allowedRole="ADMIN" userRoles={userRoles}>
              <Startsite />
            </ProtectedRoute>
          }
        />
        <Route
          path="/new/profile"
          element={
            <ProtectedRoute allowedRole="ADMIN" userRoles={userRoles}>
              <NewProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contacts"
          element={
            <ProtectedRoute allowedRole="ADMIN" userRoles={userRoles}>
              <ContactsManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="/competence"
          element={
            <ProtectedRoute allowedRole="ADMIN" userRoles={userRoles}>
              <CompetenceManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute allowedRole="CONSULTANT" userRoles={userRoles}>
              <ProfileDetails userId={editId} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/search"
          element={
            <ProtectedRoute allowedRole="ADMIN" userRoles={userRoles}>
              <ProfileSearch />
            </ProtectedRoute>
          }
        />
        <Route
          path="/editprofile"
          element={
            <ProtectedRoute allowedRole="ADMIN" userRoles={userRoles}>
              <ProfileDetails userId={editId} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/administration"
          element={
            <ProtectedRoute allowedRole="ADMIN" userRoles={userRoles}>
              <Administration />
            </ProtectedRoute>
          }
        />
        <Route
          path="/editcontactperson"
          element={
            <ProtectedRoute allowedRole="ADMIN" userRoles={userRoles}>
              <EditContactpersonData
                userId={editId}
                title="Kontaktperson bearbeiten"
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/new/contactperson"
          element={
            <ProtectedRoute allowedRole="ADMIN" userRoles={userRoles}>
              <NewContactPerson />
            </ProtectedRoute>
          }
        />
        <Route
          path="/*"
          element={
            <Navigate
              to={userRoles.includes('ADMIN') ? '/consultants' : '/profile'}
              replace
            />
          }
        ></Route>
      </Routes>
    </>
  ) : (
    <LoginForm />
  );

  return (
    <>
      <WebsiteHeader />
      {pageContent}
    </>
  );
}

export default App;
