import React, { useState, useEffect, useContext } from 'react';

import Card from '../../../components/Card/Card';
import Project from '../../../components/Project/Project';
import { useFetch } from '../../../hook/useFetch';
import { ProjectForm } from '../../../models/types';
import { AuthContext } from '../../../store/auth-context';

import NewProject from './NewProject';
import styles from './Projects.module.css';

type Props = {
  userId: number;
  title: string;
};

type ProjectList = ProjectForm[];

function Projects({ userId, title }: Props) {
  const ctx = useContext(AuthContext);
  const { bearerToken, restURL } = ctx;
  const auth = 'Bearer ' + bearerToken;

  const [projectsData, setProjectsData] = useState<ProjectList>([]);
  const [expandNewProject, setExpandNewProject] = useState(false);

  const endpointURL = `/api/v1/consultant/${userId}/fields/projects`;
  const { data, ...get } = useFetch<ProjectList>(endpointURL);

  useEffect(() => {
    if (data) {
      if (data.length === 0) {
        setProjectsData([]);
      }
      setProjectsData(data);
    }
  }, [data, setProjectsData]);

  const deleteProjectHandler = async (projectId: number) => {
    const deleteURL = `/api/v1/consultant/${userId}/field/projects/${projectId}`;
    const { httpErrorReturn } = await deleteRequest(deleteURL);
    if (!httpErrorReturn) {
      setProjectsData((prevState) =>
        prevState?.filter((entry) => entry.uid !== projectId)
      );
    }
  };

  const deleteRequest = async (deleteURL: string) => {
    let httpErrorReturn = '';
    let responseValues = '';

    try {
      const response = await fetch(restURL + deleteURL, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: auth,
        },
      });
      if (!response.ok) {
        throw new Error(`Status code: ${response.status}`);
      } else {
        responseValues = await response.json();
      }
    } catch (error) {
      httpErrorReturn = error;
    }
    return { responseValues, httpErrorReturn };
  };

  const toggleExpandNewProject = () => {
    setExpandNewProject((prevState) => !prevState);
  };

  const updateProjectHandler = (changedProject: ProjectForm) => {
    setProjectsData((prevState) => {
      const projectIndex = prevState.findIndex(
        (prevState) => prevState.uid === changedProject.uid
      );

      const updatedProject = { ...prevState[projectIndex], ...changedProject };
      const newProjectsData = [
        ...prevState.slice(0, projectIndex),
        updatedProject,
        ...prevState.slice(projectIndex + 1),
      ];
      return newProjectsData;
    });
  };

  const updateData = async () => {
    try {
      const response = await fetch(
        restURL + `/api/v1/consultant/${userId}/fields/projects`,
        {
          method: 'GET',
          headers: { Authorization: auth },
        }
      );
      if (!response.ok) {
        throw new Error('Something went wrong!!');
      }
      const result = (await response.json()) as ProjectList;
      setProjectsData(result);
    } catch (error) {
      console.error(error);
    }
  };

  let pageContent = <p></p>;
  if (get.httpError) {
    pageContent = <p>Connection error</p>;
  }
  if (get.loading) {
    pageContent = <p>Loading data ...</p>;
  }
  if (!get.loading && !get.httpError && projectsData) {
    pageContent =
      projectsData.length === 0 ? (
        <p className={styles['shift-to-right']}>Kein Projekt vorhanden.</p>
      ) : (
        <>
          {projectsData?.map((p) => (
            <Project
              key={p.uid}
              project={p}
              deleteProject={deleteProjectHandler}
              updateProject={updateProjectHandler}
            />
          ))}
        </>
      );
  }

  return (
    <Card title={title}>
      <div className={` ${projectsData.length && styles['time-axis']}`}>
        <div className={styles['button-block']}>
          <div></div>
          <div className={styles['add-project-left']}>
            <button type="button" onClick={toggleExpandNewProject}>
              +
            </button>
          </div>
          <button
            type="button"
            className={styles['add-project-right']}
            onClick={toggleExpandNewProject}
          >
            Projekterfahrung hinzufügen
          </button>
        </div>
        {expandNewProject && (
          <NewProject
            userId={userId}
            abortHandler={() => setExpandNewProject((prevState) => !prevState)}
            addNewProject={updateData}
          ></NewProject>
        )}
        {pageContent}
      </div>
    </Card>
  );
}

export default Projects;
