export async function fetchFields<T>(
  apiURL: string,
  url: string,
  auth: string
) {
  try {
    const response = await fetch(apiURL + url, {
      method: 'GET',
      headers: { Authorization: auth },
    });

    if (!response.ok) {
      throw new Error('Could not fetch from ' + url);
    }
    const data = (await response.json()) as T;
    return data;
  } catch (error) {
    throw new Error('Could not fetch from ' + url + ', error: ' + error);
  }
}
