import React from 'react';

import TableHeader from '../../../components/TableHeader/TableHeader';
import { useFetch } from '../../../hook/useFetch';
import { ConsultantList } from '../../../models/types';

import ProfileRow from './ProfileRow/ProfileRow';
import styles from './ProfileTable.module.css';

type Props = {
  filterString: string;
};

function ProfileTable({ filterString }: Props) {
  const { data, httpError, loading } = useFetch<ConsultantList>(
    '/api/v1/backoffice/tabs/consultants'
  );

  let tableContent = (
    <tr>
      <td>
        <p>No data retrieved</p>
      </td>
    </tr>
  );
  if (httpError) {
    tableContent = (
      <tr>
        <td>
          <p>Connection error</p>
        </td>
      </tr>
    );
  }
  if (loading) {
    tableContent = (
      <tr>
        <td>
          <p>Loading data ...</p>
        </td>
      </tr>
    );
  }
  if (data && data.consultants.length > 0) {
    const filteredProfiles = data.consultants.filter(
      (e) =>
        e.name.toLowerCase().includes(filterString) ||
        e.surname.toLowerCase().includes(filterString) ||
        e.level.toLowerCase().includes(filterString)
    );

    tableContent = filteredProfiles.length ? (
      <>
        {filteredProfiles.map((entry) => (
          <ProfileRow key={entry.uid} values={entry}></ProfileRow>
        ))}
      </>
    ) : (
      <tr>
        <td>
          <p>No entries found</p>
        </td>
      </tr>
    );
  }

  const pageContent = (
    <table className={styles['profile-table']}>
      <tbody>
        <tr>
          <TableHeader style={`default-heading`}>Vorname</TableHeader>
          <TableHeader style={`default-heading`}>Nachname</TableHeader>
          <TableHeader style={`default-heading`}>Geburtsdatum</TableHeader>
          <TableHeader style={`default-heading`}>Consultant Level</TableHeader>
          <TableHeader style={`default-heading`}>Profil</TableHeader>
        </tr>
        {tableContent}
      </tbody>
    </table>
  );

  return <>{pageContent}</>;
}

export default ProfileTable;
