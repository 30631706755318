import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import usePut from '../../hook/usePut';
import {
  AvailabilityForm,
  PersonalDataForm,
  ConsultantDataForm,
  ApiCreatedResponse,
} from '../../models/types';
import AvailabilityInput from '../../pages/ProfileDetails/TabContent/AvailabilityInput';
import ConsultantDataInput from '../../pages/ProfileDetails/TabContent/ConsultantDataInput';
import PersonalDataInput from '../../pages/ProfileDetails/TabContent/PersonalDataInput';
import { isApiErrorMessage } from '../../util/typeguards';

import styles from './NewProfile.module.css';

function NewProfile() {
  const [rememberedUserId, setRememberedUserId] = useState(0);
  const [nextComponent, setNextComponent] = useState('personal-data');
  const [displayedComponent, setDisplayedComponent] = useState(<p></p>);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [personalDataVal, setPersonalDataVal] = useState<PersonalDataForm>({
    name: '',
    middleName: '',
    surname: '',
    photo: '',
    dateOfBirth: '',
    sex: 'männlich',
    email: '',
    phone: '',
    mobil: '',
    title: '',
  });

  const [consultantDataVal, setConsultantDataVal] =
    useState<ConsultantDataForm>({
      role: '',
      level: '',
      shortDescription: '',
    });

  const [availValues, setAvailValues] = useState<AvailabilityForm>({
    availableFrom: '',
    hoursPerWeek: 40,
    cities: [],
    postalAreas: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
  });

  useEffect(() => {
    chooseNextComponent(nextComponent); // eslint-disable-next-line
  }, [nextComponent]);

  const navigate = useNavigate();
  const { sendPutRequest, ...put } = usePut();

  const chooseNextComponent = (nextComponent: string) => {
    switch (nextComponent) {
      case 'personal-data':
        setDisplayedComponent(
          <PersonalDataInput
            initValues={personalDataVal}
            submitData={submitPersonalDataHandler}
            onAbort={onAbortHandler}
            customRightButton="Weiter"
            extraStyling={false}
            restStatusMessage=""
          ></PersonalDataInput>
        );
        break;
      case 'consultant-data':
        setDisplayedComponent(
          <ConsultantDataInput
            initValues={consultantDataVal}
            submitData={submitConsultantDataHandler}
            onAbort={onBackwardsPersonal}
            customRightButton="Weiter"
            extraStyling={false}
            restStatusMessage=""
          ></ConsultantDataInput>
        );
        break;
      case 'consultant-avail':
        setDisplayedComponent(
          <AvailabilityInput
            initValues={availValues}
            submitData={submitAvailHandler}
            onAbort={onBackwardsConsultant}
            customRightButton="Fertig"
            extraStyling={false}
            restStatusMessage={put.httpError}
          ></AvailabilityInput>
        );
        break;
      default:
        setDisplayedComponent(<p>Error loading next form component</p>);
        break;
    }
  };

  const submitPersonalDataHandler = (props: PersonalDataForm) => {
    setPersonalDataVal(props);
    setNextComponent('consultant-data');
  };

  const submitConsultantDataHandler = (props: ConsultantDataForm) => {
    setConsultantDataVal(props);
    setNextComponent('consultant-avail');
  };

  async function fetchNewProfileData(availValues: {
    newEntries: [
      {
        city: string;
        radius: 0;
      }
    ];
    deletedEntries: number[];
    postalAreas: boolean[];
    availableFrom: string;
    hoursPerWeek: 0;
  }) {
    let localUserId = 0;
    if (rememberedUserId === 0) {
      const { responseValues, httpErrorReturn } = await sendPutRequest(
        '/api/v1/consultant/new',
        personalDataVal
      );
      if (!httpErrorReturn) {
        const respCreated =
          responseValues as any as ApiCreatedResponse<PersonalDataForm>;
        if (respCreated.created.uid) {
          setRememberedUserId(respCreated.created.uid);
          localUserId = respCreated.created.uid;
        }
        const consultantDataURL = `/api/v1/consultant/tabs/consultingData/${
          rememberedUserId ? rememberedUserId : localUserId
        }`;
        await sendPutRequest(consultantDataURL, consultantDataVal);

        const availDataURL = `/api/v1/consultant/tabs/availability/${
          rememberedUserId ? rememberedUserId : localUserId
        }`;

        await sendPutRequest(availDataURL, availValues);

        navigate('/consultants');
      } else {
        if (isApiErrorMessage(httpErrorReturn)) {
          // api has failed for whatever reason
          // quick fix, as we dont have any sort of global error handling
          // and the backend isn't telling which field failed
          setErrorMessage(
            `Die Kombination Vorname, Nachname und Geburtsdatum wird bereits genutzt.`
          );
          setNextComponent('personal-data');
        } else {
          setErrorMessage('Konnte das Profil nicht anlegen.');
        }
      }
    }
  }

  const submitAvailHandler = (finalDataAvail: {
    newEntries: [
      {
        city: string;
        radius: 0;
      }
    ];
    deletedEntries: number[];
    postalAreas: boolean[];
    availableFrom: string;
    hoursPerWeek: 0;
  }) => {
    fetchNewProfileData(finalDataAvail);
  };

  const onBackwardsPersonal = (props: ConsultantDataForm) => {
    setConsultantDataVal(props);
    setNextComponent('personal-data');
  };

  const onBackwardsConsultant = (props: AvailabilityForm) => {
    setAvailValues(props);
    setNextComponent('consultant-data');
  };

  const onAbortHandler = () => {
    navigate('/');
  };

  return (
    <>
      {errorMessage && (
        <>
          <div
            className={styles['backdrop']}
            onClick={() => {
              setErrorMessage('');
            }}
            role={'presentation'}
          ></div>
          <div className={styles['delete-dialog']}>
            <h3>{errorMessage}</h3>
            <button
              onClick={() => {
                setErrorMessage('');
              }}
            >
              Ok
            </button>
          </div>
        </>
      )}
      {displayedComponent}
    </>
  );
}
export default NewProfile;
