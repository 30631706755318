import React from 'react';

import styles from './DeleteConfirm.module.css';

type Props = {
  confirmClick: () => void;
  abortClick: () => void;
  message: string;
};

const DeleteConfirm = ({ confirmClick, abortClick, message }: Props) => {
  const abortHandler = () => {
    abortClick();
  };

  const confirmHandler = () => {
    confirmClick();
  };

  return (
    <>
      <div
        className={styles['backdrop']}
        onClick={abortHandler}
        role={'presentation'}
      ></div>
      <div className={styles['delete-dialog']}>
        <h3>{message}</h3>
        <button onClick={confirmHandler}>Entfernen</button>
        <button onClick={abortHandler}>Abbrechen</button>
      </div>
    </>
  );
};

export default DeleteConfirm;
