import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import * as Yup from 'yup';

import { AuthContext } from '../../store/auth-context';

import styles from './LoginForm.module.css';

type Credentials = {
  username: string;
  password: string;
};

function LoginForm() {
  const ctx = useContext(AuthContext);
  const { restURL } = ctx;

  const [loginErrorMessage, setLoginErrorMessage] = useState('');

  async function fetchBearerToken(credentials: Credentials) {
    setLoginErrorMessage('');
    try {
      const response = await fetch(restURL + '/auth/v1/authenticate', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(credentials),
      });
      if (!response.ok) {
        throw response.status;
      }
      const data = await response.json();
      ctx.login(data.accessToken);
    } catch (error) {
      if (error === 403) {
        setLoginErrorMessage('Username or password is invalid');
      } else {
        setLoginErrorMessage('Server unreachable');
      }
    }
  }

  const formik = useFormik({
    initialValues: { username: '', password: '' },
    validationSchema: Yup.object({
      username: Yup.string().required('Eingabe nötig'),
      password: Yup.string().required('Eingabe nötig'),
    }),
    onSubmit: () => {
      fetchBearerToken(formik.values);
    },
  });

  return (
    <>
      <form className={styles['login-form']} onSubmit={formik.handleSubmit}>
        <h2>Login</h2>
        <div className={styles['row']}>
          <div className={styles.title}>
            <p>Username:</p>
            <input
              className={
                formik.touched.username && formik.errors.username
                  ? styles['is-invalid']
                  : undefined
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
              value={formik.values.username}
              name="username"
              placeholder="Ihr Nutzername ..."
              autoComplete="username"
            ></input>
          </div>
          <span className={styles['error-message']}>
            {formik.touched.username && formik.errors.username
              ? formik.errors.username
              : null}
          </span>
        </div>
        <div className={styles['row']}>
          <div className={styles.title}>
            <p>Passwort:</p>
            <input
              className={
                formik.touched.password && formik.errors.password
                  ? styles['is-invalid']
                  : undefined
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="password"
              value={formik.values.password}
              name="password"
              placeholder="Ihr Passwort ..."
              autoComplete="current-password"
            ></input>
          </div>
          <span className={styles['error-message']}>
            {formik.touched.password && formik.errors.password
              ? formik.errors.password
              : null}
          </span>
        </div>
        <button type="submit" data-testid="login-button">
          Login
        </button>
        {loginErrorMessage && (
          <p className={styles['login-error']}>{loginErrorMessage}</p>
        )}
      </form>
    </>
  );
}

export default LoginForm;
