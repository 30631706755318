import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../store/auth-context';

import LogoImage from './Avameo_Logo_ohne.png';
import styles from './WebsiteHeader.module.css';

export default function WebsiteHeader() {
  const ctx = useContext(AuthContext);
  const navigate = useNavigate();

  const logoutHandler = () => {
    ctx.logout();
    navigate('/');
  };

  return (
    <div>
      <header className={styles.header}>
        <div className={styles.headerwrapper}>
          <img alt="Avameo Logo" src={LogoImage} className={styles.logo}></img>
        </div>
        <div className={styles.headerwrapper}>
          <h1>Consultant Manager</h1>
        </div>
        <div className={styles.headerwrapper}>
          <button
            type="button"
            className={`${styles.login} ${!ctx.loggedIn ? styles.hide : null}`}
            onClick={logoutHandler}
          >
            Logout
          </button>
        </div>
      </header>
    </div>
  );
}
