import { useContext, useState, useEffect } from 'react';

import { AuthContext } from '../store/auth-context';

export function useFetch<T>(targetURL: string) {
  const ctx = useContext(AuthContext);
  const { bearerToken, restURL } = ctx;
  const totalURL = restURL + targetURL;
  const auth = 'Bearer ' + bearerToken;
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(false);
  const [httpError, setHttpError] = useState();

  useEffect(() => {
    (async function () {
      setLoading(true);
      try {
        const response = await fetch(totalURL, {
          method: 'GET',
          headers: { Authorization: auth },
        });

        if (!response.ok) {
          throw new Error('Something went wrong!!');
        }
        const result = (await response.json()) as T;
        setData(result);
      } catch (error) {
        setHttpError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [totalURL, auth]);

  return { data, httpError, loading };
}
