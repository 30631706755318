import React, { useState, useEffect, useContext } from 'react';

import ContactTableData from '..//ContactTableData/ContactTableData';
import TableData from '../../../../components/TableData/TableData';
import { useFetch } from '../../../../hook/useFetch';
import usePut from '../../../../hook/usePut';
import {
  ContactPerson,
  Consultant,
  ApiAddResponse,
} from '../../../../models/types';
import { AuthContext } from '../../../../store/auth-context';
import { FieldsContext } from '../../../../store/fields-context';

import styles from './ContactRow.module.css';

type Props = {
  values: Consultant;
};

type ContactPersonList = ContactPerson[];

function ContactRow({ values }: Props) {
  const ctx = useContext(AuthContext);
  const { bearerToken, restURL } = ctx;
  const { contacts } = useContext(FieldsContext);
  const [contactsData, setContactsData] = useState<ContactPersonList>([]);
  const [chooseContact, setChooseContact] = useState(false);

  const auth = 'Bearer ' + bearerToken;

  const endpointURL = `/api/v1/consultant/${values.uid}/fields/contactPersons`;
  const { data } = useFetch<ContactPersonList>(endpointURL);

  const { sendPutRequest } = usePut();

  useEffect(() => {
    if (data) {
      setContactsData(data);
    }
  }, [data, setContactsData]);

  const submitData = async (contactId: string) => {
    const { responseValues, httpErrorReturn } = await sendPutRequest(
      `/api/v1/consultant/${values.uid}/field/contactPersons`,
      {
        uid: contactId,
      }
    );
    if (!httpErrorReturn) {
      const response = responseValues as ApiAddResponse<ContactPerson>;
      setContactsData((prevState) => {
        return [...prevState, response.added];
      });
    }
  };

  const deleteContactHandler = async (contactId: string) => {
    const response = await fetch(
      `${restURL}/api/v1/consultant/${values.uid}/field/contactPersons/${contactId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: auth,
        },
      }
    );
    const responseData = await response.json();
    if (!responseData.error) {
      const deleteData = responseData as any as {
        type: string;
        action: string;
        message: string;
        what: ContactPerson;
      };
      setContactsData((prevState) => {
        return prevState.filter((c) => c.uid !== deleteData.what.uid);
      });
    }
  };

  const addClickHandler = () => {
    setChooseContact((prevState) => {
      return !prevState;
    });
  };

  const addContactHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setChooseContact((prevState) => {
      return !prevState;
    });
    if (contactsData.find((c) => c.uid === +event.currentTarget.value)) {
      return;
    }
    submitData(event.currentTarget.value);
  };

  let contactList;
  const filteredContacts = contacts.filter(
    (item) => !contactsData.find((cd) => cd.uid === item.uid)
  );

  if (contactsData) {
    contactList = (
      <>
        {contactsData.length > 0 &&
          contactsData.map((contact) => (
            <ContactTableData
              key={`${values.uid.toString()}${contact.uid.toString()}`}
              contactId={contact.uid}
              deleteContact={deleteContactHandler}
            >
              {contact.name} {contact.surname}
            </ContactTableData>
          ))}
        <button
          data-testid="add-contact-button"
          type="button"
          className={styles['add-contact-button']}
          onClick={addClickHandler}
          disabled={contactsData.length >= 3 || filteredContacts.length === 0}
        ></button>
        {chooseContact && (
          <div className={styles['add-contact-input-container']}>
            <select name="contact" onChange={addContactHandler}>
              <option hidden>Kontakt auswählen ...</option>
              {filteredContacts.map((r) => (
                <option
                  key={`${values.uid} ${r.name} ${r.surname}`}
                  value={r.uid}
                >
                  {`${r.name} ${r.surname}`}
                </option>
              ))}
            </select>
          </div>
        )}
      </>
    );
  }

  return (
    <tr data-testid="contact-row">
      <TableData>
        {values.name} {values.surname}
      </TableData>
      <TableData>{contactList}</TableData>
    </tr>
  );
}

export default ContactRow;
