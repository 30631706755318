import React, { useState, useContext } from 'react';

import PageHeader from '../../components/PageHeader/PageHeader';
import { FieldsContext } from '../../store/fields-context';

import ContactTable from './ContactTable/ContactTable';

function ContactsManager() {
  const [searchInput, setSearchInput] = useState('');
  const { fieldsError } = useContext(FieldsContext);

  const searchInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value.toLowerCase());
  };

  const onClickHandler = () => {};

  return (
    <>
      <PageHeader
        title="Consultants"
        buttonTitle=""
        onClick={onClickHandler}
        searchInput={searchInputHandler}
      />
      {fieldsError && <p>{fieldsError}</p>}
      <ContactTable filterString={searchInput}></ContactTable>
    </>
  );
}

export default ContactsManager;
