import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './HeaderNavbar.module.css';

type Props = {
  userRoles: string[];
};

export default function HeaderNavbar({ userRoles }: Props) {
  let navbarContent;

  if (userRoles.includes('ADMIN')) {
    navbarContent = (
      <>
        <NavLink
          className={({ isActive }) => (isActive ? styles.active : undefined)}
          to="/consultants"
        >
          Startseite
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? styles.active : undefined)}
          to="/contacts"
        >
          Kontaktpersonen
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? styles.active : undefined)}
          to="/administration"
        >
          Verwaltung
        </NavLink>
      </>
    );
  } else {
    navbarContent = (
      <>
        <NavLink to="/profile">Mein Profil</NavLink>
      </>
    );
  }

  return (
    <div className={styles.navbar}>
      <nav className={styles.navbar}>{navbarContent}</nav>
    </div>
  );
}
