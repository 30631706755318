import React from 'react';

import styles from './TableData.module.css';

type Props = {
  children: React.ReactNode;
};

const TableData = ({ children }: Props) => {
  return <td className={styles['table-data']}>{children}</td>;
};

export default TableData;
