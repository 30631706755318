import { useFormik } from 'formik';
import React, { useEffect, useState, useContext } from 'react';
import * as Yup from 'yup';

import Card from '../../../components/Card/Card';
import { useFetch } from '../../../hook/useFetch';
import usePut from '../../../hook/usePut';
import { ApiUpdateResponse, PersonalDataForm } from '../../../models/types';
import { FieldsContext } from '../../../store/fields-context';
import { isApiErrorMessage } from '../../../util/typeguards';

import CropModal from './CropModal';
import defaultpicture from './defaultpicture.png';
import styles from './PersonalData.module.css';

type Props = {
  userId: number;
  title: string;
};
function PersonalData({ userId, title }: Props) {
  const [openCropModal, setOpenCropModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { titles, sexes } = useContext(FieldsContext);
  const [personData, setPersonData] = useState<PersonalDataForm>({
    name: '',
    middleName: '',
    surname: '',
    photo: '',
    dateOfBirth: '',
    sex: '',
    email: '',
    phone: '',
    mobil: '',
    title: '',
  });

  const endpointURL = `/api/v1/consultant/tabs/personData/${userId}`;
  const { data, ...fetch } = useFetch<PersonalDataForm>(endpointURL);

  const { sendPutRequest, ...put } = usePut();

  useEffect(() => {
    if (data) {
      setPersonData(data);
      formik.initialValues = data;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setPersonData]);

  type blurEvent =
    | React.FocusEvent<HTMLInputElement, Element>
    | React.FocusEvent<HTMLSelectElement, Element>;

  const blurHandler = (e: blurEvent) => {
    formik.handleBlur(e);
    if (Object.keys(formik.errors).length !== 0) {
      return;
    }

    const previousValue =
      personData[e.currentTarget.name as keyof PersonalDataForm];
    const newValue = e.currentTarget.value;

    if (previousValue !== newValue) {
      const submitURL = `/api/v1/consultant/${userId}/attribute/${e.target.name}`;
      submitData(e.target.value, e.target.name, submitURL);
    }
  };

  const submitData = async (
    newData: string,
    targetName: string,
    submitURL: string
  ) => {
    const { responseValues, httpErrorReturn } = await sendPutRequest(
      submitURL,
      { value: newData }
    );
    if (!httpErrorReturn) {
      const response =
        responseValues as any as ApiUpdateResponse<PersonalDataForm>;
      setPersonData((prevState) => {
        return { ...prevState, [targetName]: response.updated };
      });
      setErrorMessage('');
    } else {
      if (isApiErrorMessage(httpErrorReturn)) {
        // api has failed for whatever reason
        // quick fix, as we dont have any sort of global error handling
        // and the backend isn't telling which field failed
        setErrorMessage(
          `Die Kombination aus Vorname, Nachname und Geburtsdatum wird bereits genutzt.`
        );
      } else {
        setErrorMessage('Konnte die Kontaktperson nicht bearbeiten.');
      }
      formik.handleReset(targetName);
    }
  };

  const deletepictureHandler = () => {
    const submitURL = `/api/v1/consultant/${userId}/attribute/photo`;
    submitData('', 'photo', submitURL);
  };

  const updateCroppedPicture = (img: string) => {
    const submitURL = `/api/v1/consultant/${userId}/attribute/photo`;

    submitData(img, 'photo', submitURL);
    setOpenCropModal(false);
  };

  const pictureUploadHandler = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];
      if (file.size / 1000000 < 10) {
        const base64 = await encodeBase64(file);
        if (typeof base64 === 'string') {
          const submitURL = `/api/v1/consultant/${userId}/attribute/photo`;
          submitData(base64, 'photo', submitURL);
        }
      }
    }
  };

  const encodeBase64 = async (f: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(f);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const formik = useFormik({
    initialValues: personData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string(),
      name: Yup.string()
        .required('Eingabe nötig')
        .matches(/^[aA-zZ\s]+$/, 'Ungültiger Name: Nur Buchstaben zulässig')
        .max(30, 'Maximal 30 Zeichen zugelassen'),
      surname: Yup.string()
        .required('Eingabe nötig')
        .matches(/^[aA-zZ\s]+$/, 'Ungültiger Name: Nur Buchstaben zulässig')
        .max(30, 'Maximal 30 Zeichen zugelassen'),
      dateOfBirth: Yup.string().required('Eingabe nötig'),
      email: Yup.string()
        .email('Ungültige E-Mail Adresse')
        .max(50, 'Maximal 50 Zeichen zugelassen'),
      phone: Yup.string()
        .matches(/^\d+$/, 'Ungültige Telefonnummer: Nur Ziffern zulässig')
        .max(20, 'Maximal 20 Ziffern zugelassen'),
      mobil: Yup.string()
        .matches(/^\d+$/, 'Ungültige Handynummer: Nur Ziffern zulässig')
        .max(20, 'Maximal 20 Ziffern zugelassen'),
    }),
    onSubmit: () => {},
  });

  let pageContent = (
    <div className={styles['grid-container']}>
      <p className={styles['colum-title']}>No data retrieved </p>
    </div>
  );
  if (fetch.httpError || put.httpError) {
    pageContent = (
      <div className={styles['grid-container']}>
        <p className={styles['colum-title']}>Connection error </p>
      </div>
    );
  }
  if (fetch.loading || put.loading) {
    pageContent = (
      <div className={styles['grid-container']}>
        <p className={styles['colum-title']}>Loading data...</p>
      </div>
    );
  }

  if (data) {
    pageContent = (
      <>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles['grid-container']}>
            <p className={styles['colum-title']}>Geschlecht</p>
            <select
              className={styles['title-input']}
              value={formik.values.sex}
              onChange={formik.handleChange}
              onBlur={blurHandler}
              name="sex"
            >
              <option hidden>Auswählen ...</option>
              {sexes.map((s) => (
                <option key={s.name}>{s.name}</option>
              ))}
            </select>
            {
              <>
                {
                  <div className={styles['picture-grid']}>
                    <input
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, image/webp"
                      onChange={pictureUploadHandler}
                      className={` ${styles['picture-upload']}`}
                      title="Wähle dein Bild aus"
                      value=""
                      data-testid="uploadInput"
                    ></input>
                  </div>
                }
                {personData.photo && (
                  <>
                    <div className={styles['picture-grid']}>
                      <img
                        className={`${styles['profile-picture']} ${styles['picture-preview']}`}
                        src={personData.photo}
                        alt="Consultant"
                      />
                      <div className={styles['picture-nav']}>
                        <button type="button" onClick={deletepictureHandler}>
                          X
                        </button>{' '}
                        <button
                          type="button"
                          onClick={() => setOpenCropModal(true)}
                        >
                          {'\u2702'}
                        </button>
                      </div>
                    </div>
                    {openCropModal && (
                      <CropModal
                        image={personData.photo}
                        setOpenCropModal={setOpenCropModal}
                        openCropModal={openCropModal}
                        onCropped={updateCroppedPicture}
                      ></CropModal>
                    )}
                  </>
                )}
                {!personData.photo && (
                  <div className={styles['picture-grid']}>
                    <img
                      className={`${styles['profile-picture']} ${styles['picture-preview']}`}
                      src={defaultpicture}
                      alt="Consultant"
                    />
                  </div>
                )}
              </>
            }

            <p className={styles['colum-title']}>Titel</p>
            <select
              className={styles['title-input']}
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={blurHandler}
              name="title"
            >
              <option value={''}>Auswählen ...</option>
              {titles.map((t) => (
                <option key={t.name}>{t.name}</option>
              ))}
            </select>

            <p className={styles['colum-title']}>Vorname*</p>
            <div>
              <input
                className={
                  formik.touched.name && formik.errors.name
                    ? styles['is-invalid']
                    : undefined
                }
                type="text"
                placeholder="Ihr Vorname hier eingeben ..."
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={blurHandler}
                name="name"
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>Nachname*</p>
            <div>
              <input
                className={
                  formik.touched.surname && formik.errors.surname
                    ? styles['is-invalid']
                    : undefined
                }
                type="text"
                placeholder="Ihr Nachname hier eingeben ..."
                value={formik.values.surname}
                onChange={formik.handleChange}
                onBlur={blurHandler}
                name="surname"
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.surname && formik.errors.surname
                  ? formik.errors.surname
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>Geburtsdatum*</p>
            <div className={styles['input-error']}>
              <input
                className={
                  formik.touched.dateOfBirth && formik.errors.dateOfBirth
                    ? styles['is-invalid']
                    : undefined
                }
                type="date"
                name="dateOfBirth"
                value={formik.values.dateOfBirth}
                onChange={formik.handleChange}
                onBlur={blurHandler}
                date-testid="dateOfBirth"
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.dateOfBirth && formik.errors.dateOfBirth
                  ? formik.errors.dateOfBirth
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>E-Mail</p>
            <div>
              <input
                type="text"
                placeholder="Ihre E-Mail hier eingeben ..."
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={blurHandler}
                name="email"
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>Telefonnr.</p>
            <div>
              <input
                type="text"
                placeholder="Ihre Telefonnummer hier eingeben ..."
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={blurHandler}
                name="phone"
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.phone && formik.errors.phone
                  ? formik.errors.phone
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>Handynr.</p>
            <div>
              <input
                type="text"
                placeholder="Ihre Handynummer hier eingeben ..."
                value={formik.values.mobil}
                onChange={formik.handleChange}
                onBlur={blurHandler}
                name="mobil"
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.mobil && formik.errors.mobil
                  ? formik.errors.mobil
                  : null}
              </span>
            </div>
          </div>
          <br />
          <div className={`${styles['http-error']} ${styles['error-message']}`}>
            {errorMessage}
          </div>
        </form>
      </>
    );
  }

  return (
    <>
      <Card title={title}>{pageContent}</Card>
    </>
  );
}

export default PersonalData;
