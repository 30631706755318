import React, { useState, useEffect } from 'react';

import TableHeader from '../../../components/TableHeader/TableHeader';
import { useFetch } from '../../../hook/useFetch';
import { Consultant } from '../../../models/types';

import ContactRow from './ContactRow/ContactRow';
import styles from './ContactTable.module.css';

type Props = {
  filterString: string;
};

type ConsultantList = Consultant[];

function ContactTable({ filterString }: Props) {
  const [consultantContactsData, setConsultantContactsData] =
    useState<ConsultantList>([]);

  const endpointURL = `/api/v1/consultant/all`;
  const { data, ...get } = useFetch<ConsultantList>(endpointURL);

  useEffect(() => {
    if (data) {
      setConsultantContactsData(data);
    }
  }, [data, setConsultantContactsData]);

  let tableContent = (
    <tr>
      <td>
        <p>No data retrieved.</p>
      </td>
    </tr>
  );

  if (get.httpError) {
    tableContent = (
      <tr>
        <td>
          <p>Connection error</p>
        </td>
      </tr>
    );
  }

  if (get.loading) {
    tableContent = (
      <tr>
        <td>
          <p>Loading data ...</p>
        </td>
      </tr>
    );
  }

  if (consultantContactsData && consultantContactsData.length > 0) {
    const filteredContactsData = consultantContactsData.filter(
      (e) =>
        e.name.toLowerCase().includes(filterString) ||
        e.surname.toLowerCase().includes(filterString)
    );

    tableContent = filteredContactsData.length ? (
      <>
        {filteredContactsData.map((entry) => (
          <ContactRow
            key={entry.uid}
            values={entry}
            data-testid="contact-row"
          ></ContactRow>
        ))}
      </>
    ) : (
      <tr>
        <td>
          <p>No entries found</p>
        </td>
      </tr>
    );
  }

  return (
    <>
      <table className={styles['contact-table']}>
        <tbody>
          <tr>
            <TableHeader style={`default-heading`}>Name</TableHeader>
            <TableHeader style={`default-heading`}>Kontaktpersonen</TableHeader>
          </tr>
          {tableContent}
        </tbody>
      </table>
    </>
  );
}

export default ContactTable;
