import React from 'react';

import styles from './AreaSelection.module.css';

type Props = {
  areaChange: (updatedArea: boolean[]) => void;
  activeArea: boolean[];
  readMode: boolean;
};
const areas = ['D0', 'D1', 'D2', 'D3', 'D4', 'D5', 'D6', 'D7', 'D8', 'D9'];

function AreaSelection({ areaChange, activeArea, readMode }: Props) {
  const onAreaHandler = (event: React.MouseEvent<HTMLElement>) => {
    const changedAreas = [...activeArea];
    const buttonId = event.currentTarget.id;
    const buttonNumber = Number(buttonId);
    changedAreas[buttonNumber] = !activeArea[buttonNumber];
    activeArea[buttonNumber] = !activeArea[buttonNumber];
    areaChange([...changedAreas]);
  };

  return (
    <div
      className={`${styles.area} ${
        !readMode ? styles['area-write-mode'] : null
      }`}
    >
      {activeArea.map((area, index) => (
        <button
          key={areas[index]}
          onClick={!readMode ? onAreaHandler : undefined}
          id={`${index}`}
          type="button"
          className={area ? styles.active : undefined}
        >
          {areas[index]}
        </button>
      ))}
    </div>
  );
}

export default AreaSelection;
