import { useFormik } from 'formik';
import React, { useContext } from 'react';
import * as Yup from 'yup';

import Card from '../../../components/Card/Card';
import FormNavigation from '../../../components/FormNavigation/FormNavigation';
import { ConsultantDataForm } from '../../../models/types';
import { FieldsContext } from '../../../store/fields-context';

import styles from './ConsultantDataInput.module.css';

type Props = {
  initValues: ConsultantDataForm;
  submitData: (data: ConsultantDataForm) => void;
  onAbort: (data: ConsultantDataForm | boolean) => void;
  customRightButton: string;
  extraStyling: boolean;
  restStatusMessage: string;
};

function ConsultantDataInput({
  initValues,
  submitData,
  onAbort,
  customRightButton,
  extraStyling,
  restStatusMessage,
}: Props) {
  const { consultantRoles, consultantLevels } = useContext(FieldsContext);
  const onBackHandler = () => {
    onAbort(formik.values);
  };

  const onAbortHandler = () => {
    onAbort(true);
  };

  const formik = useFormik({
    initialValues: initValues,
    validationSchema: Yup.object({
      role: Yup.string().required('Eingabe nötig'),
      level: Yup.string().required('Eingabe nötig'),
      shortDescription: Yup.string().max(
        250,
        'Maximale Anzahl an Zeichen erreicht'
      ),
    }),
    onSubmit: () => {
      submitData(formik.values);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Card title={'Geben Sie Ihre Consulting-Daten ein.'}>
          <div className={styles['grid-container']}>
            <p className={styles['colum-title']}>Rolle*</p>
            <div className={styles['input-error']}>
              <select
                className={`${styles['title-input']} ${
                  formik.touched.role &&
                  formik.errors.role &&
                  styles['is-invalid']
                }`}
                name="role"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.role}
              >
                <option hidden>Auswählen ...</option>
                {consultantRoles.map((r) => (
                  <option key={r.name}>{r.name}</option>
                ))}
              </select>
              <span className={styles['error-message']}>
                {formik.touched.role && formik.errors.role
                  ? formik.errors.role
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>Consultant Level*</p>
            <div className={styles['input-error']}>
              <select
                className={`${styles['title-input']} ${
                  formik.touched.level &&
                  formik.errors.level &&
                  styles['is-invalid']
                }`}
                name="level"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.level}
              >
                <option hidden>Auswählen ...</option>
                {consultantLevels.map((l) => (
                  <option key={l.name}>{l.name}</option>
                ))}
              </select>
              <span className={styles['error-message']}>
                {formik.touched.level && formik.errors.level
                  ? formik.errors.level
                  : null}
              </span>
            </div>
            <p className={styles['colum-title-top']}>Kurzbeschreibung</p>
            <div>
              <textarea
                placeholder="Bitte geben Sie hier eine Kurzbeschreibung ein ..."
                cols={1}
                rows={15}
                name="shortDescription"
                onChange={formik.handleChange}
                value={formik.values.shortDescription}
              ></textarea>
              <span className={styles['error-message']}>
                {formik.touched.shortDescription &&
                formik.errors.shortDescription
                  ? formik.errors.shortDescription
                  : null}
              </span>
            </div>
          </div>
        </Card>
        <FormNavigation
          leftButton="Zurück"
          rightButton={customRightButton}
          onClickHandler={extraStyling ? onAbortHandler : onBackHandler}
          restStatusMessage={restStatusMessage}
          extraStyling={extraStyling}
        ></FormNavigation>
      </form>
    </>
  );
}

export default ConsultantDataInput;
