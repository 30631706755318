import React from 'react';

import styles from './TableHeader.module.css';

type Props = {
  style: string;
  children: React.ReactNode;
};

const TableHeader = ({ style, children }: Props) => {
  return <th className={styles[style]}>{children}</th>;
};

export default TableHeader;
