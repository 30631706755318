import React from 'react';

import styles from './Card.module.css';

type Props = {
  title: string;
  children: React.ReactNode;
};

function Card({ title, children }: Props) {
  return (
    <>
      <div className={styles.card}>
        <div className={styles.head}>
          <p>{title}</p>
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    </>
  );
}

export default Card;
