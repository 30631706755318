import React from 'react';

type Props = {
  allowedRole: string;
  userRoles: string[];
  children: React.ReactElement;
};

function ProtectedRoute({ allowedRole, userRoles, children }: Props) {
  let pageContent;

  if (userRoles.includes(allowedRole)) {
    pageContent = children;
  } else {
    pageContent = <h2>Zugriff verweigert</h2>;
  }
  return pageContent;
}

export default ProtectedRoute;
