import { useFormik } from 'formik';
import React, { useContext, useState, useEffect } from 'react';
import * as Yup from 'yup';

import Card from '../../../components/Card/Card';
import { useFetch } from '../../../hook/useFetch';
import usePut from '../../../hook/usePut';
import { ApiUpdateResponse, ConsultantDataForm } from '../../../models/types';
import { FieldsContext } from '../../../store/fields-context';

import styles from './ConsultantDataInput.module.css';

type Props = {
  userId: number;
  title: string;
};

function ConsultantDataInput({ userId, title }: Props) {
  const { consultantRoles, consultantLevels } = useContext(FieldsContext);
  const [consultantData, setConsultantData] = useState<ConsultantDataForm>({
    role: '',
    level: '',
    shortDescription: '',
  });

  const endpointURL = `/api/v1/consultant/tabs/consultingData/${userId}`;
  const { data, ...fetch } = useFetch<ConsultantDataForm>(endpointURL);

  const { sendPutRequest, ...put } = usePut();

  useEffect(() => {
    if (data) {
      setConsultantData(data);
      formik.initialValues = data;
    }
  }, [data, setConsultantData]);

  type blurEvent =
    | React.FocusEvent<HTMLInputElement, Element>
    | React.FocusEvent<HTMLSelectElement, Element>
    | React.FocusEvent<HTMLTextAreaElement, Element>;

  const blurHandler = (e: blurEvent) => {
    formik.handleBlur(e);
    if (Object.keys(formik.errors).length !== 0) {
      return;
    }

    const previousValue =
      consultantData[e.currentTarget.name as keyof ConsultantDataForm];
    const newValue = e.currentTarget.value;

    if (previousValue !== newValue) {
      const submitURL = `/api/v1/consultant/${userId}/attribute/${e.target.name}`;
      submitData(submitURL, e.target.value, e.target.name);
    }
  };

  const submitData = async (
    url: string,
    newData: string,
    targetName: string
  ) => {
    const { responseValues, httpErrorReturn } = await sendPutRequest(url, {
      value: newData,
    });
    if (!httpErrorReturn) {
      const response =
        responseValues as any as ApiUpdateResponse<ConsultantDataForm>;
      setConsultantData((prevState) => {
        return { ...prevState, [targetName]: response.updated };
      });
    } else {
      formik.handleReset(targetName);
    }
  };

  const formik = useFormik({
    initialValues: consultantData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      role: Yup.string().required('Eingabe nötig'),
      level: Yup.string().required('Eingabe nötig'),
      shortDescription: Yup.string().max(
        250,
        'Maximale Anzahl an Zeichen erreicht'
      ),
    }),
    onSubmit: () => {},
  });

  let pageContent = (
    <div className={styles['grid-container']}>
      <p className={styles['colum-title']}>No data retrieved</p>
    </div>
  );

  if (fetch.httpError || put.httpError) {
    pageContent = (
      <div className={styles['grid-container']}>
        <p className={styles['colum-title']}>Connection error</p>
      </div>
    );
  }
  if (fetch.loading || put.loading) {
    pageContent = (
      <div className={styles['grid-container']}>
        <p className={styles['colum-title']}>Loading data...</p>
      </div>
    );
  }
  if (data) {
    pageContent = (
      <>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles['grid-container']}>
            <p className={styles['colum-title']}>Rolle*</p>
            <div className={styles['input-error']}>
              <select
                className={`${styles['title-input']} ${
                  formik.touched.role &&
                  formik.errors.role &&
                  styles['is-invalid']
                }`}
                name="role"
                onChange={formik.handleChange}
                onBlur={blurHandler}
                value={formik.values.role}
              >
                <option hidden>Auswählen ...</option>
                {consultantRoles.map((r) => (
                  <option key={r.name}>{r.name}</option>
                ))}
              </select>
              <span className={styles['error-message']}>
                {formik.touched.role && formik.errors.role
                  ? formik.errors.role
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>Consultant Level*</p>
            <div className={styles['input-error']}>
              <select
                className={`${styles['title-input']} ${
                  formik.touched.level &&
                  formik.errors.level &&
                  styles['is-invalid']
                }`}
                name="level"
                onChange={formik.handleChange}
                onBlur={blurHandler}
                value={formik.values.level}
              >
                <option hidden>Auswählen ...</option>
                {consultantLevels.map((l) => (
                  <option key={l.name}>{l.name}</option>
                ))}
              </select>
              <span className={styles['error-message']}>
                {formik.touched.level && formik.errors.level
                  ? formik.errors.level
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>Kurzbeschreibung</p>
            <div>
              <textarea
                placeholder="Bitte geben Sie hier eine Kurzbeschreibung ein ..."
                cols={1}
                rows={15}
                name="shortDescription"
                onChange={formik.handleChange}
                onBlur={blurHandler}
                value={formik.values.shortDescription}
              ></textarea>
              <span className={styles['error-message']}>
                {formik.touched.shortDescription &&
                formik.errors.shortDescription
                  ? formik.errors.shortDescription
                  : null}
              </span>
            </div>
          </div>
        </form>
      </>
    );
  }

  return (
    <>
      <Card title={title}>{pageContent}</Card>
    </>
  );
}

export default ConsultantDataInput;
