import React from 'react';

import Card from '../../../components/Card/Card';
import { useFetch } from '../../../hook/useFetch';

import styles from './ContactPersons.module.css';
import SinglePerson from './SinglePerson';

type Props = {
  userId: number;
  title: string;
};

export type ConsultantContact = {
  name: string;
  surname: string;
  title: string;
  sex: string;
  role: string;
  phone: string;
  email: string;
};

function ContactPersons({ userId, title }: Props) {
  const endpointURL = `/api/v1/consultant/tabs/contactPersons/${userId}`;

  const { data, httpError, loading } =
    useFetch<ConsultantContact[]>(endpointURL);
  const contactPersons = data;
  let pageContent = (
    <p className={styles['shift-to-right']}>No data retrieved</p>
  );

  if (httpError) {
    pageContent = <p className={styles['shift-to-right']}>Connection error</p>;
  }
  if (loading) {
    pageContent = <p className={styles['shift-to-right']}>Loading data...</p>;
  }

  if (contactPersons && contactPersons.length > 0) {
    pageContent = (
      <>
        {contactPersons.map((entry) => (
          <SinglePerson
            values={entry}
            key={entry.name + entry.surname}
          ></SinglePerson>
        ))}
      </>
    );
  } else {
    pageContent = (
      <p className={styles['shift-to-right']}>
        Keine Kontaktperson zugeordnet.
      </p>
    );
  }

  return (
    <>
      <Card title={title}>{pageContent}</Card>
    </>
  );
}

export default ContactPersons;
