import React, { useContext, useRef, useEffect, useState } from 'react';

import Card from '../../../components/Card/Card';
import { useFetch } from '../../../hook/useFetch';
import usePut from '../../../hook/usePut';
import {
  ApiAddResponse,
  ApiUpdateResponse,
  Language,
} from '../../../models/types';
import { AuthContext } from '../../../store/auth-context';
import { FieldsContext } from '../../../store/fields-context';

import styles from './Languages.module.css';

type Props = {
  userId: number;
  title: string;
};

function Languages({ userId, title }: Props) {
  const endpointURL = `/api/v1/consultant/tabs/languages/${userId}`;
  const ctx = useContext(AuthContext);
  const { bearerToken, restURL } = ctx;
  const auth = 'Bearer ' + bearerToken;
  const languageRef = useRef<HTMLSelectElement>(null);
  const levelRef = useRef<HTMLSelectElement>(null);
  const [languageData, setLanguageData] = useState<Language[]>([]);

  const { languages, languageLevels } = useContext(FieldsContext);

  const { data, ...get } = useFetch<Language[]>(endpointURL);
  const { sendPutRequest } = usePut();

  useEffect(() => {
    if (data) {
      setLanguageData(data);
    }
  }, [data]);

  const addLanguageHandler = async () => {
    const newLang = languageRef.current?.value;
    const newLevel = levelRef.current?.value;

    if (newLang === 'Auswählen ...' || newLevel === 'Auswählen ...') {
      return;
    }
    const newLanguage = {
      language: newLang,
      level: newLevel,
    };
    const addURL = `/api/v1/consultant/${userId}/field/languages`;
    const { responseValues, httpErrorReturn } = await sendPutRequest(
      addURL,
      newLanguage
    );
    if (!httpErrorReturn) {
      const response = responseValues as any as ApiAddResponse<Language>;
      setLanguageData((prevState) => {
        return [...prevState, response.added];
      });
    }
    resetInputFields();
  };

  const deleteLanguageHandler = async (uid: number) => {
    const deleteURL = `/api/v1/consultant/${userId}/field/languages/${uid}`;
    const { httpErrorReturn } = await deleteRequest(deleteURL);
    if (!httpErrorReturn) {
      setLanguageData((prevState) => {
        return prevState?.filter((entry) => entry.uid !== uid);
      });
    }
  };

  const deleteRequest = async (deleteURL: string) => {
    let httpErrorReturn = '';
    let responseValues = '';

    try {
      const response = await fetch(restURL + deleteURL, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: auth,
        },
      });
      if (!response.ok) {
        throw new Error(`Status code: ${response.status}`);
      } else {
        responseValues = await response.json();
      }
    } catch (error) {
      httpErrorReturn = error;
    }
    return { responseValues, httpErrorReturn };
  };

  const updateEntry = async (newData: Language, submitURL: string) => {
    const { responseValues, httpErrorReturn } = await sendPutRequest(
      submitURL,
      newData
    );

    if (!httpErrorReturn) {
      const response = responseValues as any as ApiUpdateResponse<Language>;
      const updatedArray = languageData.map((e) => {
        if (e.uid === response.updated.uid) {
          return response.updated;
        } else {
          return e;
        }
      });
      setLanguageData(updatedArray);
    }
  };

  const blurHandler = (e: React.FocusEvent<HTMLSelectElement, Element>) => {
    const entryName = e.target.name;
    const submitURL = `/api/v1/consultant/${userId}/field/languages/${entryName}`;
    const updatedLang = languageData.find(
      (e) => e.uid.toString() === entryName
    );
    if (updatedLang?.level === e.currentTarget.value) {
      return;
    }
    if (updatedLang !== undefined) {
      updatedLang.level = e.target.value;
      updateEntry(updatedLang, submitURL);
    }
  };
  const resetInputFields = () => {
    if (languageRef.current) {
      languageRef.current.value = 'Auswählen ...';
    }
    if (levelRef.current) {
      levelRef.current.value = 'Auswählen ...';
    }
  };

  let pageContent = <></>;

  if (get.httpError) {
    pageContent = (
      <>
        <div className={styles.languages}>
          <p>Connection error</p>
        </div>
      </>
    );
  }
  if (get.loading) {
    pageContent = (
      <>
        <div className={styles.languages}>
          <p>Loading data...</p>
        </div>
      </>
    );
  }
  if (languageData && languageData.length > 0) {
    pageContent = (
      <>
        {languageData.map((entry) => (
          <div
            key={entry.uid + entry.language + entry.level}
            className={styles.languages}
          >
            <p data-testid="language">{entry.language}</p>
            <select
              defaultValue={entry.level}
              onBlur={blurHandler}
              name={entry.uid.toString()}
              id={entry.uid.toString()}
            >
              {languageLevels.map((ll) => (
                <option key={ll.name} id={ll.name}>
                  {ll.name}
                </option>
              ))}
            </select>
            <button
              data-testid="deleteButton"
              className={styles['move-to-end']}
              onClick={() => {
                deleteLanguageHandler(entry.uid);
              }}
            >
              X
            </button>
          </div>
        ))}
      </>
    );
  }

  return (
    <Card title={title}>
      <div className={styles.languages}>
        <h2>Sprachen</h2>
        <h2>Niveau</h2>
      </div>
      {pageContent}
      <div className={styles.languages}>
        <select ref={languageRef}>
          <option hidden>Auswählen ...</option>
          {languages
            .filter(
              (lang) =>
                !languageData.find((data) => {
                  return data.language == lang.name;
                })
            )
            .map((l) => (
              <option key={l.name}>{l.name}</option>
            ))}
        </select>
        <select ref={levelRef}>
          <option hidden>Auswählen ...</option>
          {languageLevels.map((ll) => (
            <option key={ll.name}>{ll.name}</option>
          ))}
        </select>
        <button
          className={styles['move-to-end']}
          type="button"
          onClick={addLanguageHandler}
        >
          Hinzufügen
        </button>
      </div>
    </Card>
  );
}

export default Languages;
