import { useContext, useState, useCallback } from 'react';

import { ApiErrorMessage } from '../models/types';
import { AuthContext } from '../store/auth-context';

export type usePutResponse<T> = {
  httpError: string;
  setHttpError: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  sendPutRequest: (
    url: string,
    data: any
  ) => Promise<{
    responseValues: string | T;
    httpErrorReturn: string | ApiErrorMessage;
  }>;
};

const usePut = <T>(): usePutResponse<T> => {
  const ctx = useContext(AuthContext);
  const { bearerToken, restURL } = ctx;
  const [loading, setLoading] = useState(false);
  const [httpError, setHttpError] = useState('');
  let responseValues: string | T = '';
  let httpErrorReturn: string | ApiErrorMessage = '';
  const auth = 'Bearer ' + bearerToken;

  const sendPutRequest = useCallback(
    async (url: string, data: any) => {
      httpErrorReturn = '';
      setLoading(true);
      try {
        const response = await fetch(restURL + url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: auth,
          },
          body: JSON.stringify(data),
        });
        if (!response.ok) {
          httpErrorReturn = (await response.json()) as ApiErrorMessage;
        } else {
          responseValues = await response.json();
          setHttpError('');
        }
      } catch (error) {
        setHttpError(error);
        httpErrorReturn = error;
      }
      setLoading(false);
      return { responseValues, httpErrorReturn };
    },
    [restURL, auth]
  );

  return {
    httpError,
    setHttpError,
    loading,
    setLoading,
    sendPutRequest,
  };
};

export default usePut;
