import React from 'react';

import styles from './TabNavItem.module.css';

type Props = {
  title: string;
  index: number;
  selectTab: (index: number) => void;
  activeTabs: boolean[];
};

function TabNavItem({ title, index, selectTab, activeTabs }: Props) {
  const onClickHandler = () => {
    selectTab(index);
  };

  return (
    <li>
      <button
        className={`${styles['profile-nav-button']} ${
          activeTabs[index] && styles['active']
        }`}
        onClick={onClickHandler}
      >
        {title}
      </button>
    </li>
  );
}

export default TabNavItem;
