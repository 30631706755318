import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFetch } from '../../hook/useFetch';
import { ContactPerson } from '../../models/types';
import ProfileRow2 from '../../pages/Startsite/ProfileTable/ProfileRow/ProfileRow2';
import { AuthContext } from '../../store/auth-context';
import Card from '../Card/Card';
import DeleteConfirm from '../DeleteConfirm/DeleteConfirm';
import TableHeader from '../TableHeader/TableHeader';

import styles from './AllContactPersonsTable.module.css';

type Props = {
  title: string;
};

function AllContactPersonsTable({ title }: Props) {
  const navigate = useNavigate();

  const ctx = useContext(AuthContext);
  const { bearerToken, restURL } = ctx;
  const auth = 'Bearer ' + bearerToken;

  const { data, httpError, loading } = useFetch<ContactPerson[]>(
    '/api/v1/fields/contactPerson/all'
  );
  const [contactPersons, setContactPersons] = useState<ContactPerson[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(0);

  useEffect(() => {
    if (data) {
      setContactPersons(data);
    }
  }, [data]);

  const removeContactPerson = (uid: number) => {
    setContactPersons((prevState) => {
      return prevState?.filter((entry) => entry.uid !== uid);
    });
  };

  const onAddContactPersonHandler = () => {
    navigate('/new/contactperson');
  };

  const onCancelModal = () => setOpenModal(false);
  const onConfirmModal = async (uid: number) => {
    const url = `/api/v1/fields/contactPerson/${uid}`;
    const response = await fetch(restURL + url, {
      method: 'DELETE',
      headers: {
        Authorization: auth,
      },
    });
    if (!response.ok) {
      throw new Error(`Status code: ${response.status}`);
    } else {
      removeContactPerson(uid);
    }
    setOpenModal(false);
  };

  let tableContent = (
    <tr>
      <td>
        <p>Es existiert keine Kontaktperson</p>
      </td>
    </tr>
  );
  if (httpError) {
    tableContent = (
      <tr>
        <td>
          <p>Connection error</p>
        </td>
      </tr>
    );
  }
  if (loading) {
    tableContent = (
      <tr>
        <td>
          <p>Loading data ...</p>
        </td>
      </tr>
    );
  }
  if (contactPersons && contactPersons.length > 0) {
    tableContent = contactPersons.length ? (
      <>
        {contactPersons.map((entry) => (
          <ProfileRow2
            key={entry.uid}
            values={entry}
            setOpenModal={setOpenModal}
            setToBeDeleted={setToBeDeleted}
          />
        ))}
      </>
    ) : (
      <tr>
        <td>
          <p>No entries found</p>
        </td>
      </tr>
    );
  }

  const pageContent = (
    <>
      <div className={`${styles.leftbutton}`}>
        <button type="button" onClick={onAddContactPersonHandler}>
          {`+ Hinzufügen`}
        </button>
      </div>
      <table className={styles['all-contactpersons-table']}>
        <tbody>
          <tr>
            <TableHeader style={`default-heading`}>Vorname</TableHeader>
            <TableHeader style={`default-heading`}>Nachname</TableHeader>
            <TableHeader style={`default-heading`}>Profil</TableHeader>
          </tr>
          {tableContent}
        </tbody>
      </table>
      {openModal && (
        <DeleteConfirm
          confirmClick={() => onConfirmModal(toBeDeleted)}
          abortClick={onCancelModal}
          message={
            'Kontaktperson wirklich löschen? Das löscht auch alle Zuordnungen zu Consultants.'
          }
        />
      )}
    </>
  );

  return (
    <>
      <Card title={title}>{pageContent}</Card>
    </>
  );
}

export default AllContactPersonsTable;
