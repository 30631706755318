import * as Yup from 'yup';

export const newContactPersonValidationSchema = Yup.object({
  sex: Yup.string().required('Auswahl nötig'),
  role: Yup.string().required('Auswahl nötig'),
  title: Yup.string(),
  name: Yup.string()
    .required('Eingabe nötig')
    .matches(/^[aA-zZ\s]+$/, 'Ungültiger Name: Nur Buchstaben zulässig')
    .max(30, 'Maximal 30 Zeichen zugelassen')
    .trim(),
  surname: Yup.string()
    .required('Eingabe nötig')
    .matches(/^[aA-zZ\s]+$/, 'Ungültiger Name: Nur Buchstaben zulässig')
    .max(30, 'Maximal 30 Zeichen zugelassen')
    .trim(),
  email: Yup.string()
    .required('Eingabe nötig')
    .email('Ungültige E-Mail Adresse')
    .max(50, 'Maximal 50 Zeichen zugelassen'),
  phone: Yup.string()
    .required('Eingabe nötig')
    .matches(/^\d+$/, 'Ungültige Telefonnummer: Nur Ziffern zulässig')
    .max(20, 'Maximal 20 Ziffern zugelassen'),
  mobil: Yup.string()
    .matches(/^\d+$/, 'Ungültige Handynummer: Nur Ziffern zulässig')
    .max(20, 'Maximal 20 Ziffern zugelassen'),
});

export const newProjectValidationSchema = Yup.object({
  start: Yup.string().required('Auswahl nötig'),
  end: Yup.date().when(
    'start',
    (startDate, schema) =>
      startDate &&
      schema.min(startDate, 'Startdatum muss vor dem Enddatum liegen')
  ),
  name: Yup.string()
    .required('Eingabe nötig')
    .max(30, 'Maximal 30 Zeichen zugelassen')
    .trim(),
  role: Yup.string().required('Auswahl nötig'),
  descriptionLong: Yup.string().max(250, 'Maximal 250 Zeichen zugelassen'),
  company: Yup.string().required('Auswahl nötig'),
});
