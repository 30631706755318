import React from 'react';

import Tabs from '../../pages/ProfileDetails/Tabs';

import Availability from './TabContent/Availability';
import Certificates from './TabContent/Certificates';
import ConsultantData from './TabContent/ConsultantData';
import ContactPersons from './TabContent/ContactPersons';
import Languages from './TabContent/Languages';
import PersonalData from './TabContent/PersonalData';
import Projects from './TabContent/Projects';
import SoftwareSkills from './TabContent/SoftwareSkills';
import Trainings from './TabContent/Trainings';

type Props = {
  userId: number;
};

function ProfileDetails({ userId }: Props) {
  return (
    <Tabs>
      <PersonalData userId={userId} title="Persönliche Daten"></PersonalData>
      <ConsultantData userId={userId} title="Consulting Daten"></ConsultantData>
      <ContactPersons title="Kontaktpersonen" userId={userId} />
      <Projects title="Projekte" userId={userId} />
      <SoftwareSkills title="Aus- /Weiterbildung" userId={userId} />
      <Certificates title="Zertifizierung" userId={userId} />
      <Availability userId={userId} title="Verfügbarkeit" />
      <Trainings title="Kompetenzen" userId={userId} />
      <Languages userId={userId} title="Sprachkenntnisse"></Languages>
    </Tabs>
  );
}

export default ProfileDetails;
