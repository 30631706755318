import React from 'react';

import AllContactPersonsTable from '../../components/AllContactPersonsTable/AllContactPersonsTable';
import Tabs from '../ProfileDetails/Tabs';

function Administration() {
  return (
    <>
      <Tabs>
        <AllContactPersonsTable title="Alle Kontaktpersonen" />
        <div title=""></div>
      </Tabs>
    </>
  );
}

export default Administration;
