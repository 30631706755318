import React from 'react';

import Card from '../../../components/Card/Card';

import { ConsultantContact } from './ContactPersons';
import styles from './SinglePerson.module.css';

type Props = {
  values: ConsultantContact;
};

function SinglePerson({ values }: Props) {
  return (
    <Card title="Kontaktperson">
      <div className={styles['grid-container']}>
        <p className={styles['colum-title']}>Name</p>
        {values.title + ' ' + values.name + ' ' + values.surname}
        <p className={styles['colum-title']}>Funktion</p>
        <p>{values.role}</p>
        <p className={styles['colum-title']}>E-Mail</p>
        <p>{values.email}</p>
        <p className={styles['colum-title']}>Telefonnummer</p>
        <p>{values.phone}</p>
      </div>
    </Card>
  );
}

export default SinglePerson;
