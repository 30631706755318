import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import TableData from '../../../../components/TableData/TableData';
import { ConsultantListEntry } from '../../../../models/types';
import { AuthContext } from '../../../../store/auth-context';

type Props = { values: ConsultantListEntry };

const ProfileRow = ({ values }: Props) => {
  const navigate = useNavigate();
  const { setEditId } = useContext(AuthContext);

  useEffect(() => {
    const formatDate = (rawDate: string) => {
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      };
      const _ = new Date(rawDate).toLocaleDateString([], options);
      setFormattedDate(_);
    };

    formatDate(values.dateOfBirth);
  }, [values.dateOfBirth]);

  const [formattedDate, setFormattedDate] = useState('');

  const onClickHandler = () => {
    setEditId(values.uid);
    navigate('/editprofile');
  };

  return (
    <tr>
      <TableData>{values.name}</TableData>
      <TableData>{values.surname}</TableData>
      <TableData>{formattedDate}</TableData>
      <TableData>{values.level}</TableData>
      <TableData>
        {
          <button type="button" onClick={onClickHandler}>
            Zum Profil
          </button>
        }
      </TableData>
    </tr>
  );
};

export default ProfileRow;
