import React from 'react';

import styles from './PageHeader.module.css';

type Props = {
  onClick: () => void;
  searchInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title: string;
  buttonTitle: string;
};

function PageHeader({ onClick, searchInput, title, buttonTitle }: Props) {
  return (
    <div className={styles.header}>
      <div className={styles.wrapper}>
        {buttonTitle && (
          <button
            type="button"
            className={`${styles.leftbutton} button`}
            onClick={onClick}
          >
            {`+ ${buttonTitle}`}
          </button>
        )}
      </div>
      <div className={styles.wrapper}>
        <h2>{title}</h2>
      </div>
      <div className={styles.wrapper}>
        <input
          className={styles['search-input']}
          onChange={searchInput}
          type="text"
          placeholder="Durchsuchen"
          data-testid="search-input"
        ></input>
      </div>
    </div>
  );
}

export default PageHeader;
