import { useFormik } from 'formik';
import React, { useState, useContext, useEffect } from 'react';
import * as Yup from 'yup';
import 'yup-phone';

import Card from '../../../components/Card/Card';
import FormNavigation from '../../../components/FormNavigation/FormNavigation';
import { PersonalDataForm } from '../../../models/types';
import { FieldsContext } from '../../../store/fields-context';

import CropModal from './CropModal';
import defaultpicture from './defaultpicture.png';
import styles from './PersonalData.module.css';

type Props = {
  initValues: PersonalDataForm;
  submitData: (data: {
    name: string;
    middleName: string;
    surname: string;
    dateOfBirth: string;
    sex: string;
    email: string;
    phone: string;
    mobil: string;
    title: string;
  }) => void;
  onAbort: (abort: boolean) => void;
  customRightButton: string;
  extraStyling: boolean;
  restStatusMessage: string;
};

function PersonalDataInput({
  initValues,
  submitData,
  onAbort,
  customRightButton,
  extraStyling,
  restStatusMessage,
}: Props) {
  const [openCropModal, setOpenCropModal] = useState(false);
  const [postImg, setPostImg] = useState('');
  const { titles, sexes } = useContext(FieldsContext);

  useEffect(() => {
    if (initValues.photo) {
      setPostImg(initValues.photo);
    }
  }, []);

  const formik = useFormik({
    initialValues: initValues,
    validationSchema: Yup.object({
      sex: Yup.string().required('Eingabe nötig'),
      name: Yup.string()
        .required('Eingabe nötig')
        .matches(/^[aA-zZ\s]+$/, 'Ungültiger Name: Nur Buchstaben zulässig')
        .max(30, 'Maximal 30 Zeichen zugelassen')
        .trim(),
      surname: Yup.string()
        .required('Eingabe nötig')
        .matches(/^[aA-zZ\s]+$/, 'Ungültiger Name: Nur Buchstaben zulässig')
        .max(30, 'Maximal 30 Zeichen zugelassen')
        .trim(),
      middleName: Yup.string()
        .matches(/^[aA-zZ\s]+$/, 'Ungültiger Name: Nur Buchstaben zulässig')
        .max(30, 'Maximal 30 Zeichen zugelassen')
        .trim(),
      dateOfBirth: Yup.string().required('Eingabe nötig'),
      photo: Yup.string(),
      email: Yup.string()
        .required('Eingabe nötig')
        .email('Ungültige E-Mail Adresse')
        .max(50, 'Maximal 50 Zeichen zugelassen'),
      phone: Yup.string()
        .matches(/^\d+$/, 'Ungültige Telefonnummer: Nur Ziffern zulässig')
        .max(20, 'Maximal 20 Ziffern zugelassen'),
      mobil: Yup.string()
        .matches(/^\d+$/, 'Ungültige Handynummer: Nur Ziffern zulässig')
        .max(20, 'Maximal 20 Ziffern zugelassen'),
    }),
    onSubmit: () => {
      const personalDataSubmitObject = {
        name: formik.values.name,
        middleName: formik.values.middleName,
        surname: formik.values.surname,
        dateOfBirth: formik.values.dateOfBirth,
        photo: postImg,
        sex: formik.values.sex,
        email: formik.values.email,
        phone: formik.values.phone,
        mobil: formik.values.mobil,
        title: formik.values.title,
      };
      submitData(personalDataSubmitObject);
    },
  });

  const onAbortHandler = () => {
    onAbort(true);
  };

  const deletepictureHandler = () => {
    setPostImg('');
  };

  const pictureUploadHandler = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files?.length) {
      const file = event.target.files[0];
      if (file.size / 1000000 < 10) {
        const base64 = await encodeBase64(file);
        if (typeof base64 === 'string') {
          setPostImg(base64);
        }
      }
    } else {
      setPostImg('');
    }
  };

  const encodeBase64 = async (f: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(f);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const updateCroppedPicture = (img: string) => {
    setPostImg(img);
    setOpenCropModal(false);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Card title={'Geben Sie Ihre Persönlichen Daten ein.'}>
          <div className={styles['grid-container']}>
            <p className={styles['colum-title']}>Geschlecht</p>
            <select
              className={styles['title-input']}
              value={formik.values.sex}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="sex"
            >
              <option hidden>Auswählen ...</option>
              {sexes.map((s) => (
                <option key={s.name}>{s.name}</option>
              ))}
            </select>

            <>
              <div className={styles['picture-grid']}>
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, image/webp"
                  onChange={pictureUploadHandler}
                  className={` ${styles['picture-upload']}`}
                  title="Wähle dein Bild aus"
                  value=""
                  data-testid="uploadInput"
                ></input>
              </div>

              {postImg ? (
                <>
                  <div className={styles['picture-grid']}>
                    <img
                      className={`${styles['profile-picture']} ${styles['picture-preview']}`}
                      src={postImg}
                      alt="Consultant"
                    />
                    <div className={styles['picture-nav']}>
                      <button type="button" onClick={deletepictureHandler}>
                        X
                      </button>
                      <button
                        type="button"
                        onClick={() => setOpenCropModal(true)}
                      >
                        {'\u2702'}
                      </button>
                    </div>
                  </div>
                  {openCropModal && (
                    <CropModal
                      image={postImg}
                      setOpenCropModal={setOpenCropModal}
                      openCropModal={openCropModal}
                      onCropped={updateCroppedPicture}
                    ></CropModal>
                  )}
                </>
              ) : (
                <div className={styles['picture-grid']}>
                  <img
                    className={`${styles['profile-picture']} ${styles['picture-preview']}`}
                    src={defaultpicture}
                    alt="Consultant"
                  />
                </div>
              )}
            </>

            <p className={styles['colum-title']}>Titel</p>
            <select
              className={styles['title-input']}
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="title"
            >
              <option hidden>Auswählen ...</option>
              {titles.map((t) => (
                <option key={t.name}>{t.name}</option>
              ))}
            </select>
            <p className={styles['colum-title']}>Vorname*</p>
            <div>
              <input
                className={
                  formik.touched.name && formik.errors.name
                    ? styles['is-invalid']
                    : undefined
                }
                type="text"
                placeholder="Ihr Vorname hier eingeben ..."
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="name"
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>Nachname*</p>
            <div>
              <input
                className={
                  formik.touched.surname && formik.errors.surname
                    ? styles['is-invalid']
                    : undefined
                }
                type="text"
                placeholder="Ihr Nachname hier eingeben ..."
                value={formik.values.surname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="surname"
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.surname && formik.errors.surname
                  ? formik.errors.surname
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>Geburtsdatum*</p>
            <div className={styles['input-error']}>
              <input
                className={
                  formik.touched.dateOfBirth && formik.errors.dateOfBirth
                    ? styles['is-invalid']
                    : undefined
                }
                type="date"
                name="dateOfBirth"
                value={formik.values.dateOfBirth}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.dateOfBirth && formik.errors.dateOfBirth
                  ? formik.errors.dateOfBirth
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>E-Mail*</p>
            <div>
              <input
                type="text"
                placeholder="Ihre E-Mail hier eingeben ..."
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="email"
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>Telefonnr.</p>
            <div>
              <input
                type="text"
                placeholder="Ihre Telefonnummer hier eingeben ..."
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="phone"
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.phone && formik.errors.phone
                  ? formik.errors.phone
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>Handynr.</p>
            <div>
              <input
                type="text"
                placeholder="Ihre Handynummer hier eingeben ..."
                value={formik.values.mobil}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="mobil"
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.mobil && formik.errors.mobil
                  ? formik.errors.mobil
                  : null}
              </span>
            </div>
          </div>
        </Card>

        <FormNavigation
          leftButton="Abbrechen"
          rightButton={customRightButton}
          onClickHandler={onAbortHandler}
          restStatusMessage={restStatusMessage}
          extraStyling={extraStyling}
        ></FormNavigation>
      </form>
    </>
  );
}

export default PersonalDataInput;
