import React from 'react';

type Props = {
  userId: number;
  title: string;
};

function SoftwareSkills({ title }: Props) {
  return <h2>{title}</h2>;
}

export default SoftwareSkills;
