import React, { useState, useContext, useEffect } from 'react';

import {
  CityField,
  TitleField,
  ConsultantLevelField,
  ConsultantRoleField,
  ContactPersonField,
  CompanyField,
  LanguageField,
  LanguageLevelField,
  PostalCodeField,
  SexField,
  SkillField,
  IndustryField,
  ContactRole,
} from '../models/types';

import { AuthContext } from './auth-context';
import { fetchFields } from './fetchFields';

type Props = {
  children: React.ReactNode;
};

type FieldsContextObj = {
  cities: CityField[];
  consultantLevels: ConsultantLevelField[];
  consultantRoles: ConsultantRoleField[];
  contacts: ContactPersonField[];
  companies: CompanyField[];
  titles: TitleField[];
  industries: IndustryField[];
  languages: LanguageField[];
  languageLevels: LanguageLevelField[];
  postalCodes: PostalCodeField[];
  sexes: SexField[];
  skills: SkillField[];
  contactRoles: ContactRole[];
  fetchFieldsHandler: () => void;
  fieldsError: string;
};

export const FieldsContext = React.createContext<FieldsContextObj>({
  cities: [],
  consultantLevels: [],
  consultantRoles: [],
  contacts: [],
  companies: [],
  titles: [],
  industries: [],
  languages: [],
  languageLevels: [],
  postalCodes: [],
  sexes: [],
  skills: [],
  contactRoles: [],
  fetchFieldsHandler: () => {},
  fieldsError: '',
});

const FieldsContextProvider = ({ children }: Props) => {
  const [cities, setCities] = useState<CityField[]>([]);
  const [consultantLevels, setConsultantLevels] = useState<
    ConsultantLevelField[]
  >([]);
  const [consultantRoles, setConsultantRoles] = useState<ConsultantRoleField[]>(
    []
  );
  const [contacts, setContacts] = useState<ContactPersonField[]>([]);
  const [companies, setCompanies] = useState<CompanyField[]>([]);
  const [titles, setTitles] = useState<TitleField[]>([]);
  const [industries, setIndustries] = useState<IndustryField[]>([]);
  const [languages, setLanguages] = useState<LanguageField[]>([]);
  const [languageLevels, setLanguageLevels] = useState<LanguageLevelField[]>(
    []
  );
  const [postalCodes, setPostalCodes] = useState<PostalCodeField[]>([]);
  const [sexes, setSexes] = useState<SexField[]>([]);
  const [skills, setSkills] = useState<SkillField[]>([]);
  const [contactRoles, setContactRoles] = useState<ContactRole[]>([]);
  const [fieldsError, setFieldsError] = useState('');

  const storedToken = localStorage.getItem('restToken');
  const ctx = useContext(AuthContext);
  const { bearerToken, restURL } = ctx;
  const auth = 'Bearer ' + bearerToken;
  const apiURL = restURL + '/api/v1/fields';

  useEffect(() => {
    if (storedToken) {
      fetchFieldsHandler();
    }
  }, [storedToken]);

  const getField = async <T,>(url: string): Promise<T> => {
    return fetchFields(apiURL, url, auth);
  };

  const fetchFieldsHandler = async () => {
    try {
      const [
        cities,
        consultantLevels,
        consultantRoles,
        contacts,
        companies,
        titles,
        industries,
        languages,
        languageLevels,
        postalCodes,
        sexes,
        skills,
        contactRoles,
      ] = await Promise.all([
        getField<CityField[]>('/city/all'),
        getField<ConsultantLevelField[]>('/consultantLevel/all'),
        getField<ConsultantRoleField[]>('/consultantRole/all'),
        getField<ContactPersonField[]>('/contactPerson/all'),
        getField<CompanyField[]>('/company/all'),
        getField<TitleField[]>('/honoraryTitle/all'),
        getField<IndustryField[]>('/industry/all'),
        getField<LanguageField[]>('/language/all'),
        getField<LanguageLevelField[]>('/languageLevel/all'),
        getField<PostalCodeField[]>('/postalCode/all'),
        getField<SexField[]>('/sex/all'),
        getField<SkillField[]>('/skill/all'),
        getField<ContactRole[]>('/contactRole/all'),
      ]);

      setCities(cities);
      setConsultantLevels(consultantLevels);
      setConsultantRoles(consultantRoles);
      setContacts(contacts);
      setCompanies(companies);
      setTitles(titles);
      setIndustries(industries);
      setLanguages(languages);
      setLanguageLevels(languageLevels);
      setPostalCodes(postalCodes);
      setSexes(sexes);
      setSkills(skills);
      setContactRoles(contactRoles);
    } catch (err) {
      setFieldsError('Could not load all fields!');
    }
  };

  const fieldsContextValues: FieldsContextObj = {
    cities,
    consultantLevels,
    consultantRoles,
    contacts,
    companies,
    titles,
    industries,
    languages,
    languageLevels,
    postalCodes,
    sexes,
    skills,
    contactRoles,
    fetchFieldsHandler,
    fieldsError,
  };

  return (
    <FieldsContext.Provider value={fieldsContextValues}>
      {children}
    </FieldsContext.Provider>
  );
};

export default FieldsContextProvider;
