import React from 'react';

import Card from '../../../components/Card/Card';
import usePut from '../../../hook/usePut';
import { ApiAddResponse, ProjectForm } from '../../../models/types';

import EditProjectForm from './EditProjectForm';
import styles from './NewProject.module.css';

type Props = {
  userId: number;
  abortHandler: () => void;
  addNewProject: (project: ProjectForm) => void;
};

function NewProject({ userId, abortHandler, addNewProject }: Props) {
  const { sendPutRequest } = usePut();

  const submitHandler = async (data: ProjectForm) => {
    const { responseValues, httpErrorReturn } = await sendPutRequest(
      `/api/v1/consultant/${userId}/field/projects`,
      data
    );
    const response = responseValues as any as ApiAddResponse<ProjectForm>;
    if (!httpErrorReturn) {
      abortHandler();
      addNewProject(response.added);
    }
  };

  return (
    <div className={styles['new-project-container']}>
      <div>
        <Card title={'Geben Sie Ihre Projekt Daten ein.'}>
          <EditProjectForm
            abortHandler={abortHandler}
            restCall={submitHandler}
          ></EditProjectForm>
        </Card>
      </div>
    </div>
  );
}

export default NewProject;
