import React from 'react';

import styles from './FormNavigation.module.css';

type Props = {
  leftButton: string;
  rightButton: string;
  onClickHandler: (event: React.MouseEvent<HTMLElement>) => void;
  extraStyling: boolean;
  restStatusMessage?: string;
};

function FormNavigation({
  leftButton,
  rightButton,
  onClickHandler,
  extraStyling,
  restStatusMessage,
}: Props) {
  return (
    <div
      className={`${styles['navigation']} ${
        !extraStyling ? styles.distant : undefined
      } ${extraStyling ? styles.close : undefined}`}
    >
      {extraStyling && (
        <span className={styles['http-error']}>
          <p>{restStatusMessage}</p>
        </span>
      )}
      <button onClick={onClickHandler} type="button">
        {leftButton}
      </button>
      {!extraStyling && (
        <span className={styles['http-error']}>
          <p>{restStatusMessage}</p>
        </span>
      )}
      <button type="submit">{rightButton} </button>
    </div>
  );
}

export default FormNavigation;
