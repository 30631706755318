import { useFormik } from 'formik';
import React, { useState, useEffect, useContext, useRef } from 'react';
import * as Yup from 'yup';

import AreaSelection from '../../../components/AreaSelection/AreaSelection';
import Card from '../../../components/Card/Card';
import { useFetch } from '../../../hook/useFetch';
import usePut from '../../../hook/usePut';
import {
  ApiAddResponse,
  ApiUpdateResponse,
  AvailabilityForm,
  City,
} from '../../../models/types';
import { AuthContext } from '../../../store/auth-context';
import { FieldsContext } from '../../../store/fields-context';

import styles from './Availability.module.css';

type Props = {
  userId: number;
  title: string;
};

export type AvailabilityFormData = {
  hoursPerWeek: number;
  availableFrom: string;
  cities: City[];
  postalAreas: boolean[];
};

function AvailabilityInput({ userId, title }: Props) {
  const ctx = useContext(AuthContext);
  const { bearerToken, restURL } = ctx;
  const { cities } = useContext(FieldsContext);
  const [availabilityData, setAvailabilityData] = useState<AvailabilityForm>({
    hoursPerWeek: 0,
    availableFrom: '',
    cities: [],
    postalAreas: [],
  });
  const [newCity, setNewCity] = useState('');
  const [newRadius, setNewRadius] = useState(0);
  const cityRef = useRef<HTMLSelectElement>(null);
  const radiusRef = useRef<HTMLSelectElement>(null);

  const auth = 'Bearer ' + bearerToken;

  const endpointURL = `/api/v1/consultant/tabs/availability/${userId}`;
  const { data, ...get } = useFetch<AvailabilityForm>(endpointURL);
  const { sendPutRequest, ...put } = usePut();

  useEffect(() => {
    if (data) {
      setAvailabilityData(data);
      formik.initialValues = data;
    }
  }, [data, setAvailabilityData]); // eslint-disable-line react-hooks/exhaustive-deps

  type blurEvent =
    | React.FocusEvent<HTMLInputElement, Element>
    | React.FocusEvent<HTMLSelectElement, Element>;

  const submitHandler = async (
    type: string,
    url: string,
    newData: { value: string } | boolean[] | { city: string; radius: number },
    targetName: string
  ) => {
    if (type === 'blur' || type === 'area') {
      const { responseValues, httpErrorReturn } = await sendPutRequest(
        url,
        newData
      );
      if (!httpErrorReturn) {
        const response =
          responseValues as any as ApiUpdateResponse<AvailabilityForm>;
        setAvailabilityData((prevState) => {
          return { ...prevState, [targetName]: response.updated };
        });
      }
    } else if (type === 'city') {
      const { responseValues, httpErrorReturn } = await sendPutRequest(
        url,
        newData
      );
      if (!httpErrorReturn) {
        const response = responseValues as any as ApiAddResponse<City>;
        setAvailabilityData((prevState) => {
          return {
            ...prevState,
            cities: [...prevState.cities, response.added],
          };
        });
        if (cityRef.current) {
          cityRef.current.value = 'Auswählen ...';
        }
        if (radiusRef.current) {
          radiusRef.current.value = '0';
        }
      }
    }
  };

  const deleteHandler = async (url: string) => {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth,
      },
    });
    const responseData = await response.json();
    if (responseData) {
      const deleteData = responseData as any as {
        type: string;
        action: string;
        message: string;
        what: City;
      };
      setAvailabilityData((prevState) => {
        return {
          ...prevState,
          cities: prevState.cities.filter((c) => c.uid !== deleteData.what.uid),
        };
      });
    }
  };

  const blurHandler = (e: blurEvent) => {
    formik.handleBlur(e);
    if (Object.keys(formik.errors).length !== 0) {
      return;
    }

    const previousValue =
      availabilityData[e.currentTarget.name as keyof AvailabilityForm];
    let newValue: string | number = e.currentTarget.value;

    if (e.currentTarget.type === 'number') {
      newValue = Number(e.currentTarget.value);
    }

    if (previousValue !== newValue) {
      const submitURL = `/api/v1/consultant/${userId}/attribute/${e.target.name}`;
      submitHandler(
        'blur',
        submitURL,
        { value: e.target.value },
        e.target.name
      );
    }
  };

  const formik = useFormik({
    initialValues: availabilityData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      availableFrom: Yup.string().required('Eingabe nötig'),
      hoursPerWeek: Yup.number().integer(),
    }),
    onSubmit: () => {},
  });

  const setNewCityHandler = (
    event: React.FocusEvent<HTMLSelectElement, Element>
  ) => {
    setNewCity(event.target.value);
  };

  const setNewRadiusHandler = (
    event: React.FocusEvent<HTMLSelectElement, Element>
  ) => {
    const toNumber = parseInt(event.target.value);
    setNewRadius(toNumber);
  };

  const addCityHandler = async () => {
    const url = `/api/v1/consultant/${userId}/field/locations`;
    submitHandler('city', url, { city: newCity, radius: newRadius }, 'cities');
  };

  const removeCityHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    const val = event.currentTarget.value;
    const url = restURL + `/api/v1/consultant/${userId}/field/locations/${val}`;
    deleteHandler(url);
  };

  const areaChangeHandler = (areas: boolean[]) => {
    const url = `/api/v1/consultant/${userId}/attribute/postalArea`;
    submitHandler('area', url, areas, 'postalAreas');
  };

  let pageContent = (
    <>
      <div className={styles['grid-container']}>
        <p className={styles['colum-title']}>No data retrieved...</p>
      </div>
    </>
  );

  const filteredCities = cities.filter(
    (c) =>
      !availabilityData.cities.find((city) => {
        return c.name == city.city;
      })
  );

  if (get.httpError || put.httpError) {
    pageContent = (
      <>
        <div className={styles['grid-container']}>
          <p className={styles['colum-title']}>Connection error</p>
        </div>
      </>
    );
  }
  if (get.loading || put.loading) {
    pageContent = (
      <>
        <div className={styles['grid-container']}>
          <p className={styles['colum-title']}>Loading data...</p>
        </div>
      </>
    );
  }

  if (data) {
    pageContent = (
      <>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles['grid-container']}>
            <p className={styles['colum-title']}>Verfügbar ab*</p>
            <div className={styles['input-error']}>
              <input
                className={
                  formik.touched.availableFrom && formik.errors.availableFrom
                    ? styles['is-invalid']
                    : undefined
                }
                data-testid="date-input"
                type="date"
                name="availableFrom"
                value={formik.values.availableFrom}
                onChange={formik.handleChange}
                onBlur={blurHandler}
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.availableFrom && formik.errors.availableFrom
                  ? formik.errors.availableFrom
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>Verfügbare Stunden/ Woche:*</p>
            <div className={styles['input-error']}>
              <input
                className={
                  formik.touched.hoursPerWeek && formik.errors.hoursPerWeek
                    ? styles['is-invalid']
                    : undefined
                }
                type="number"
                name="hoursPerWeek"
                min="0"
                max="48"
                step="1"
                onChange={formik.handleChange}
                onBlur={blurHandler}
                value={formik.values.hoursPerWeek}
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.hoursPerWeek && formik.errors.hoursPerWeek
                  ? formik.errors.hoursPerWeek
                  : null}
              </span>
            </div>
            <br></br>
            <input
              onChange={formik.handleChange}
              onBlur={blurHandler}
              name="hoursPerWeek"
              type="range"
              min="0"
              max="48"
              step="1"
              value={formik.values.hoursPerWeek}
            ></input>
            <p className={styles['colum-title']}>Einsatzorte</p>
            <hr />
            <p className={styles['colum-title']}>Städte*</p>
            <div className={styles.city}>
              <select
                name="city"
                ref={cityRef}
                onChange={formik.handleChange}
                onBlur={setNewCityHandler}
                defaultValue="Auswählen ..."
                disabled={filteredCities.length === 0}
              >
                <option hidden>Auswählen ...</option>
                {filteredCities.map((c) => (
                  <option key={c.name}>{c.name}</option>
                ))}
              </select>
              <select
                name="radius"
                ref={radiusRef}
                onChange={formik.handleChange}
                onBlur={setNewRadiusHandler}
                defaultValue="0"
                disabled={filteredCities.length === 0}
              >
                <option value="0">0 km</option>
                <option value="10">10 km</option>
                <option value="20">20 km</option>
                <option value="50">50 km</option>
                <option value="100">100 km</option>
                <option value="150">150 km</option>
                <option value="200">200 km</option>
                <option value="300">300 km</option>
                <option value="500">500 km</option>
              </select>
              <button onClick={addCityHandler} type="button">
                Hinzufügen
              </button>
            </div>
            <br></br>
            <div data-testid="city">
              {availabilityData.cities.map((c) => (
                <div className={styles.city} key={c.city}>
                  <span>{c.city}</span>
                  <span>{c.radius} km</span>
                  <button
                    type="button"
                    value={c.uid}
                    onClick={removeCityHandler}
                  >
                    X
                  </button>
                </div>
              ))}
            </div>
            <p className={styles['colum-title']}>PLZ-Gebiete</p>
            <AreaSelection
              areaChange={areaChangeHandler}
              activeArea={availabilityData.postalAreas}
              readMode={false}
            ></AreaSelection>
          </div>
        </form>
      </>
    );
  }

  return <Card title={title}>{pageContent}</Card>;
}

export default AvailabilityInput;
