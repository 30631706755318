import React from 'react';

import { City } from '../../models/types';

import styles from './CityHelper.module.css';

type Props = {
  editRadiusHandler: (city: string, newRadius: number) => void;
  editNameHandler: (city: string, radius: number, newName: string) => void;
  onDeleteCity: (uid: number) => void;
  values: City;
  key: string;
};

function CityHelper({
  editRadiusHandler,
  editNameHandler,
  onDeleteCity,
  values,
}: Props) {
  const onRadiusChangeHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const radiusNumber = Number(event.target.value);
    editRadiusHandler(values.city, radiusNumber);
  };

  const onNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    editNameHandler(values.city, values.radius, event.target.value);
  };

  const onDeleteHandler = () => {
    onDeleteCity(values.uid);
  };

  return (
    <>
      <div className={styles['city-entry']}>
        <input
          type="text"
          defaultValue={values.city}
          onChange={onNameChangeHandler}
        ></input>
        <select
          onChange={onRadiusChangeHandler}
          defaultValue={values.radius.toString()}
        >
          <option value="10">10 km</option>
          <option value="20">20 km</option>
          <option value="50">50 km</option>
          <option value="100">100 km</option>
          <option value="150">150 km</option>
          <option value="200">200 km</option>
          <option value="300">300 km</option>
          <option value="500">500 km</option>
        </select>
        <button onClick={onDeleteHandler} type="button">
          X
        </button>
      </div>
    </>
  );
}

export default CityHelper;
