import React, { useState } from 'react';

import DeleteConfirm from '../../../../components/DeleteConfirm/DeleteConfirm';

import styles from './ContactTableData.module.css';

type Props = {
  key: string;
  children: React.ReactNode;
  contactId: number;
  deleteContact: (contactId: string) => void;
};

function ContactTableData({ children, contactId, deleteContact }: Props) {
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState('');

  const deleteClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDeleteInfo(event.currentTarget.value);
    setDeleteConfirm(true);
  };

  const deleteContactHandler = () => {
    deleteContact(deleteInfo);
    setDeleteConfirm(false);
  };

  const abortDeleteHandler = () => {
    setDeleteConfirm(false);
  };

  return (
    <>
      {deleteConfirm && (
        <DeleteConfirm
          confirmClick={deleteContactHandler}
          abortClick={abortDeleteHandler}
          message={'Kontakt wirklich entfernen?'}
        />
      )}
      <div className={styles['contact-table-data']}>{children}</div>
      <button
        type="button"
        className={styles['contact-button']}
        onClick={deleteClickHandler}
        value={contactId}
      >
        X
      </button>
    </>
  );
}

export default ContactTableData;
