import { useFormik } from 'formik';
import React, { useEffect, useState, useContext } from 'react';

import { useFetch } from '../../hook/useFetch';
import usePut from '../../hook/usePut';
import {
  ApiUpdateResponse,
  ContactPersonResponse,
  NewContactPersonForm,
} from '../../models/types';
import { FieldsContext } from '../../store/fields-context';
import { newContactPersonValidationSchema } from '../../util/schemes';
import { isApiErrorMessage } from '../../util/typeguards';
import Card from '../Card/Card';

import styles from './EditContactpersonData.module.css';

type Props = {
  userId: number;
  title: string;
};

type blurEvent =
  | React.FocusEvent<HTMLInputElement, Element>
  | React.FocusEvent<HTMLSelectElement, Element>;

function convertContactPerson(
  person: ContactPersonResponse
): NewContactPersonForm {
  return {
    ...person,
    title: person.title ? person.title.name : '',
    sex: person.sex ? person.sex.name : '',
    role: person.role ? person.role.name : '',
  };
}

function EditContactpersonData({ userId, title }: Props) {
  const { titles, sexes, contactRoles } = useContext(FieldsContext);
  const [personData, setPersonData] = useState<NewContactPersonForm>({
    name: '',
    surname: '',
    sex: '',
    email: '',
    phone: '',
    mobil: '',
    title: '',
    role: '',
  });

  const endpointURL = `/api/v1/fields/contactPerson/${userId}`;
  const { data, ...fetch } = useFetch<ContactPersonResponse>(endpointURL);
  const { sendPutRequest, ...put } = usePut();
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (data) {
      setPersonData(convertContactPerson(data));
    }
  }, [data, setPersonData]);

  const sendUpdatedValues = (targetName: string) => {
    if (Object.keys(formik.errors).length === 0) {
      const submitUrl = `/api/v1/fields/contactPerson/${userId}`;
      submitData(targetName, submitUrl);
    }
  };

  const onBlurHandler = (e: blurEvent) => {
    formik.handleBlur(e);
    const previousValue =
      personData[e.currentTarget.name as keyof NewContactPersonForm];
    if (previousValue !== e.currentTarget.value) {
      sendUpdatedValues(e.currentTarget.name);
    }
  };

  const submitData = async (targetName: string, submitURL: string) => {
    const { responseValues, httpErrorReturn } = await sendPutRequest(
      submitURL,
      formik.values
    );
    if (!httpErrorReturn) {
      const response =
        responseValues as ApiUpdateResponse<ContactPersonResponse>;
      setPersonData(convertContactPerson(response.updated));
      setErrorMessage('');
    } else {
      if (isApiErrorMessage(httpErrorReturn)) {
        // api has failed for whatever reason
        // quick fix, as we dont have any sort of global error handling
        // and the backend isn't telling which field failed
        setErrorMessage(`E-Mail ${formik.values.email} wird bereits genutzt.`);
      } else {
        setErrorMessage('Konnte die Kontaktperson nicht bearbeiten.');
      }
      formik.handleReset(targetName);
    }
  };

  const formik = useFormik({
    initialValues: personData,
    enableReinitialize: true,
    validationSchema: newContactPersonValidationSchema,
    onSubmit: () => {},
  });

  let pageContent = (
    <div className={styles['grid-container']}>
      <p className={styles['colum-title']}>No data retrieved</p>
    </div>
  );

  if (fetch.httpError || put.httpError) {
    pageContent = (
      <div className={styles['grid-container']}>
        <p className={styles['colum-title']}>Connection error</p>
      </div>
    );
  }
  if (fetch.loading || put.loading) {
    pageContent = (
      <div className={styles['grid-container']}>
        <p className={styles['colum-title']}>Loading data...</p>
      </div>
    );
  }

  if (data) {
    pageContent = (
      <>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles['grid-container']}>
            <p className={styles['colum-title']}>Geschlecht</p>
            <select
              className={styles['title-input']}
              value={formik.values.sex}
              onChange={formik.handleChange}
              onBlur={onBlurHandler}
              name="sex"
            >
              <option hidden>Auswählen ...</option>
              {sexes.map((s) => (
                <option key={s.name}>{s.name}</option>
              ))}
            </select>

            <p className={styles['colum-title']}>Rolle</p>
            <select
              className={styles['title-input']}
              value={formik.values.role}
              onChange={formik.handleChange}
              onBlur={onBlurHandler}
              name="role"
            >
              <option hidden>Auswählen ...</option>
              {contactRoles.map((t) => (
                <option key={t.name}>{t.name}</option>
              ))}
            </select>

            <p className={styles['colum-title']}>Titel</p>
            <select
              className={styles['title-input']}
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={onBlurHandler}
              name="title"
            >
              <option value={''}>Auswählen ...</option>
              {titles.map((t) => (
                <option key={t.name}>{t.name}</option>
              ))}
            </select>

            <p className={styles['colum-title']}>Vorname*</p>
            <div className={styles['smaller']}>
              <input
                className={
                  formik.touched.name && formik.errors.name
                    ? styles['is-invalid']
                    : undefined
                }
                type="text"
                placeholder="Ihr Vorname hier eingeben ..."
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={onBlurHandler}
                name="name"
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>Nachname*</p>
            <div className={styles['smaller']}>
              <input
                className={
                  formik.touched.surname && formik.errors.surname
                    ? styles['is-invalid']
                    : undefined
                }
                type="text"
                placeholder="Ihr Nachname hier eingeben ..."
                value={formik.values.surname}
                onChange={formik.handleChange}
                onBlur={onBlurHandler}
                name="surname"
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.surname && formik.errors.surname
                  ? formik.errors.surname
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>E-Mail*</p>
            <div className={styles['smaller']}>
              <input
                type="text"
                placeholder="Ihre E-Mail hier eingeben ..."
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={onBlurHandler}
                name="email"
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null}
              </span>
            </div>
            <p className={styles['colum-title']}>Telefonnr.</p>
            <div className={styles['smaller']}>
              <input
                type="text"
                placeholder="Ihre Telefonnummer hier eingeben ..."
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={onBlurHandler}
                name="phone"
              ></input>
              <span className={styles['error-message']}>
                {formik.touched.phone && formik.errors.phone
                  ? formik.errors.phone
                  : null}
              </span>
            </div>
          </div>
          <br />
          <div className={`${styles['http-error']} ${styles['error-message']}`}>
            {errorMessage}
          </div>
        </form>
      </>
    );
  }

  return (
    <>
      <Card title={title}>{pageContent}</Card>
    </>
  );
}

export default EditContactpersonData;
