import React, { ReactElement, useState } from 'react';

import TabNavItem from './TabNavItem/TabNavItem';
import styles from './Tabs.module.css';

type Props = {
  children: ReactElement[];
};

function Tabs({ children }: Props) {
  const [selectedTab, setSelectedTab] = useState(0);

  const [activeTab, setActiveTab] = useState(
    new Array(children.length).fill(false)
  );

  const onSelectHandler = (index: number) => {
    setSelectedTab(index);
    const chooseActive = (index: number, activeTab: boolean[]) => {
      const newActiveTab = activeTab.fill(false);
      newActiveTab[index] = true;
      setActiveTab(newActiveTab);
    };
    chooseActive(index, activeTab);
  };

  return (
    <>
      <div className={styles['all-tabs']}>
        <ul className={styles['tab-nav']}>
          {children
            .filter((item) => item.props.title !== '')
            .map((item, index) => (
              <TabNavItem
                key={index}
                title={item.props.title}
                index={index}
                selectTab={onSelectHandler}
                activeTabs={activeTab}
              />
            ))}
        </ul>
      </div>
      {children[selectedTab]}
    </>
  );
}

export default Tabs;
