import { useFormik } from 'formik';
import React, { useContext, useRef, useState, useEffect } from 'react';

import { ProjectForm } from '../../../models/types';
import { FieldsContext } from '../../../store/fields-context';
import { newProjectValidationSchema } from '../../../util/schemes';

import styles from './EditProjectForm.module.css';

type Props = {
  values?: ProjectForm;
  abortHandler: () => void;
  restCall: (data: ProjectForm) => void;
};

function EditProjectForm({ values, abortHandler, restCall }: Props) {
  const { consultantRoles, companies, skills, industries } =
    useContext(FieldsContext);
  const skillRef = useRef<HTMLSelectElement>(null);
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);

  const industryRef = useRef<HTMLSelectElement>(null);
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);

  const defaultOption = 'Auswählen ...';

  useEffect(() => {
    if (values) {
      setSelectedSkills(values.skills);
      setSelectedIndustries(values.industries);
    }
  }, []);

  const handleNullEndDate = () => {
    if (values) {
      return values.end ? values.end : '';
    }
    return '';
  };

  const formik = useFormik({
    initialValues: values
      ? {
          uid: values.uid,
          name: values.name,
          start: values.start,
          end: `${handleNullEndDate()}`,
          role: values.role,
          skills: values.skills,
          descriptionLong: values.descriptionLong,
          company: values.company,
          industries: values.industries,
        }
      : {
          name: '',
          start: '',
          end: '',
          role: '',
          skills: [],
          descriptionLong: '',
          company: '',
          industries: [],
        },
    validationSchema: newProjectValidationSchema,
    onSubmit: (initialValues, helpers) => {
      restCall({
        ...formik.values,
        skills: [...selectedSkills],
        industries: [...selectedIndustries],
      });
      helpers.resetForm();
    },
  });

  const allAvailableSkillsSelected = skills.length === selectedSkills.length;
  const allAvailableIndustriesSelected =
    industries.length === selectedIndustries.length;

  const addSkillHandler = () => {
    if (skillRef.current) {
      const newSkill = skillRef.current.value;
      if (newSkill !== defaultOption) {
        setSelectedSkills((prevState) => {
          if (prevState.includes(newSkill)) {
            return [...prevState];
          }
          return [...prevState, newSkill];
        });
      }
    }
  };

  const deleteSelectedSkillHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const val = event.currentTarget.value;
    setSelectedSkills((prevState) => prevState.filter((s) => s !== val));
  };

  const addIndustryHandler = () => {
    if (industryRef.current) {
      const newIndustry = industryRef.current.value;
      if (newIndustry !== defaultOption) {
        setSelectedIndustries((prevState) => {
          if (prevState.includes(newIndustry)) {
            return [...prevState];
          }
          return [...prevState, newIndustry];
        });
      }
    }
  };

  const deleteSelectedIndustrieHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const val = event.currentTarget.value;
    setSelectedIndustries((prevState) => prevState.filter((i) => i !== val));
  };

  return (
    <form
      className={styles['new-project-container']}
      onSubmit={formik.handleSubmit}
    >
      <div className={styles['new-project-form-container']}>
        <div className={styles['title-column']}>Titel*</div>
        <div className={styles['input-column']}>
          <input
            className={
              formik.touched.name && formik.errors.name
                ? styles['is-invalid']
                : undefined
            }
            type="text"
            placeholder="Titel hier eingeben ..."
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="name"
          ></input>
          <span className={styles['error-message']}>
            {formik.touched.name && formik.errors.name
              ? formik.errors.name
              : null}
          </span>
        </div>
        <div className={styles['title-column']}>Start-Datum*</div>
        <div className={styles['input-column']}>
          <input
            className={
              formik.touched.start && formik.errors.start
                ? styles['is-invalid']
                : undefined
            }
            data-testid="date-input-start"
            type="date"
            name="start"
            value={formik.values.start}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          ></input>
          <span className={styles['error-message']}>
            {formik.touched.start && formik.errors.start
              ? formik.errors.start
              : null}
          </span>
        </div>
        <div className={styles['title-column']}>End-Datum</div>
        <div className={styles['input-column']}>
          <input
            className={
              formik.touched.end && formik.errors.end
                ? styles['is-invalid']
                : undefined
            }
            data-testid="date-input-end"
            type="date"
            name="end"
            value={formik.values.end}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          ></input>
          <span className={styles['error-message']}>
            {formik.touched.end && formik.errors.end ? formik.errors.end : null}
          </span>
          <div className={styles['end-comment']}>
            Feld freilassen, wenn End-Datum unbekannt oder Projekt noch laufend
          </div>
        </div>
        <div className={styles['title-column']}>Rolle*</div>
        <div className={styles['input-column']}>
          <select
            value={formik.values.role}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="role"
          >
            <option hidden>{defaultOption}</option>
            {consultantRoles.map((cr) => (
              <option key={cr.name}>{cr.name}</option>
            ))}
          </select>
          <span className={styles['error-message']}>
            {formik.touched.role && formik.errors.role
              ? formik.errors.role
              : null}
          </span>
        </div>
        <div className={styles['title-column']}>Skills</div>
        <div>
          <select ref={skillRef} disabled={allAvailableSkillsSelected}>
            <option hidden>{defaultOption}</option>
            {skills
              .filter((item) => !selectedSkills.find((cs) => cs === item.name))
              .map((s) => (
                <option key={s.name}>{s.name}</option>
              ))}
          </select>
          <button
            type="button"
            onClick={addSkillHandler}
            disabled={allAvailableSkillsSelected}
          >
            Hinzufügen
          </button>
        </div>
        <div className={styles['input-column']}>
          {selectedSkills.map((s) => (
            <span className={styles['tech-button']} key={s}>
              <span>{s}</span>
              <button
                type="button"
                value={s}
                onClick={deleteSelectedSkillHandler}
              >
                X
              </button>
            </span>
          ))}
        </div>
        <div className={styles['title-column']}>Beschreibung</div>
        <div className={styles['input-column']}>
          <textarea
            placeholder="Bitte geben Sie hier eine Beschreibung ein ..."
            cols={50}
            rows={15}
            name="descriptionLong"
            maxLength={251}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.descriptionLong}
          ></textarea>
          <span className={styles['error-message']}>
            {formik.touched.descriptionLong && formik.errors.descriptionLong
              ? formik.errors.descriptionLong
              : null}
          </span>
        </div>
        <div className={styles['title-column']}>Kunde*</div>
        <div className={styles['input-column']}>
          <select
            value={formik.values.company}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="company"
          >
            <option hidden>{defaultOption}</option>
            {companies.map((c) => (
              <option key={c.name}>{c.name}</option>
            ))}
          </select>
          <span className={styles['error-message']}>
            {formik.touched.company && formik.errors.company
              ? formik.errors.company
              : null}
          </span>
        </div>
        <div className={styles['title-column']}>Branchen</div>
        <div>
          <select ref={industryRef} disabled={allAvailableIndustriesSelected}>
            <option hidden>{defaultOption}</option>
            {industries
              .filter(
                (item) => !selectedIndustries.find((ci) => ci === item.name)
              )
              .map((i) => (
                <option key={i.name}>{i.name}</option>
              ))}
          </select>
          <button
            type="button"
            onClick={addIndustryHandler}
            disabled={allAvailableIndustriesSelected}
          >
            Hinzufügen
          </button>
        </div>
        <div className={styles['input-column']}>
          {selectedIndustries.map((i) => (
            <span className={styles['tech-button']} key={i}>
              <span>{i}</span>
              <button
                type="button"
                value={i}
                onClick={deleteSelectedIndustrieHandler}
              >
                X
              </button>
            </span>
          ))}
        </div>
      </div>
      <div className={styles['new-project-footer']}>
        <button type="button" onClick={abortHandler}>
          Abbrechen
        </button>
        <button type="submit" data-testid="submit-button">
          Hinzufügen
        </button>
      </div>
    </form>
  );
}

export default EditProjectForm;
