import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';

import styles from './CropModal.module.css';

type Props = {
  image: string;
  setOpenCropModal: (val: boolean) => void;
  openCropModal: boolean;
  onCropped: (img: string) => void;
};

function CropModal({
  image,
  setOpenCropModal,
  openCropModal,
  onCropped,
}: Props) {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [selectedCrop, setSelectedCrop] = useState<Area>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });
  const cropCompleteHandler = (croppedArea: Area, croppedAreaPixels: Area) => {
    setSelectedCrop(croppedAreaPixels);
  };

  function createImage(img: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = img;
    });
  }

  const generateCrop = async () => {
    const tmpImg = await createImage(image);

    const canvas = document.createElement('canvas');

    const ctx = canvas.getContext('2d');
    canvas.width = tmpImg.naturalWidth;
    canvas.height = tmpImg.naturalHeight;

    ctx!.drawImage(tmpImg, 0, 0);

    const data = ctx!.getImageData(
      selectedCrop.x,
      selectedCrop.y,
      selectedCrop.width,
      selectedCrop.height
    );
    canvas.width = selectedCrop.width;
    canvas.height = selectedCrop.height;

    ctx!.putImageData(data, 0, 0);

    onCropped(canvas.toDataURL('image/jpeg'));
  };

  return (
    <>
      <dialog open={openCropModal}>
        <div className={styles['crop-dialog']}>
          <Cropper
            image={image}
            crop={crop}
            onCropChange={setCrop}
            onCropComplete={cropCompleteHandler}
            cropSize={{ width: 200, height: 250 }}
            showGrid={false}
            style={{
              containerStyle: {
                width: '100%',
                height: '350px',
                backgroundColor: '#fff',
              },
            }}
          ></Cropper>
        </div>
        <div className={styles['crop-buttons']}>
          <button type="button" onClick={() => setOpenCropModal(false)}>
            Abbrechen
          </button>
          <button type="button" onClick={generateCrop}>
            Übernehmen
          </button>
        </div>
      </dialog>
    </>
  );
}

export default CropModal;
